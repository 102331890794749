// Delete cookie:
// document.cookie =
//   "timeshopAgeValidation=; expires=Thu, 01 Jan 1970 00:00:00 UTC";

class AgeValidationPopup {
	private readonly confirmAgeBtn: HTMLElement | null;
	private eligible: boolean | null = null;

	constructor(private readonly element: HTMLElement) {
		this.confirmAgeBtn = document.querySelector<HTMLElement>("#confirmAgeBtn");
		this.checkForCookie();
	}

	public addEventListeners(): void {
		switch (this.eligible) {
			case null:
				this.element.addEventListener("click", e => {
					e.preventDefault();

					$.magnificPopup.open({
						items: {
							src: "#ageValidation"
						},
						type: "inline"
					});
				});
				this.validateAge();
				break;

			case true:
				this.element.addEventListener("click", () => this.navigateToTimeShop());
				break;

			default:
				this.element.addEventListener("click", () => this.openPdfForm());
		}
	}

	private checkForCookie(): void {
		const timeShopCookie = document.cookie
			.split(";")
			.map(c => c.split("=").map(x => decodeURIComponent(x).trim()))
			.map(kvp => ({
				key: kvp[0],
				value: kvp[1]
			}))
			.find(kvp => kvp.key === "timeshopAgeValidation");

		if (timeShopCookie) {
			this.eligible = Boolean(JSON.parse(timeShopCookie.value));
		}
	}

	private validateAge(): void {
		const button = this.confirmAgeBtn;
		if (!button) {
			throw new Error("Could not find confirmation button");
		}

		button.addEventListener("click", e => {
			e.preventDefault();

			const { year, day, month } = this.getBirthdayInputValues();

			const requiredAge = 13;
			const birthdate = new Date(year, month - 1, day);
			const cutOffDate = birthdate.setFullYear(
				birthdate.getFullYear() + requiredAge
			);

			const now = new Date();
			this.eligible = now.valueOf() >= cutOffDate.valueOf();

			const cookieLifespan = 259200000; // 3 days worth of milliseconds
			const expiryDate = new Date(new Date().getTime() + cookieLifespan);
			document.cookie = `timeshopAgeValidation=${this.eligible}; expires=${expiryDate}`;

			if (this.eligible) {
				this.navigateToTimeShop();
			} else {
				this.openPdfForm();
				$.magnificPopup.close();
				location.reload();
			}
		});
	}

	private getBirthdayInputValues(): {
		year: number;
		day: number;
		month: number;
	} {
		const _yearElem = document.getElementById(
			"year-select"
		) as HTMLSelectElement;

		const year = parseInt(_yearElem.options[_yearElem.selectedIndex].text, 10);

		const _dayElem = document.getElementById("day-select") as HTMLSelectElement;

		const day = parseInt(_dayElem.options[_dayElem.selectedIndex].text, 10);

		const _monthElem = document.getElementById(
			"month-select"
		) as HTMLSelectElement;

		const month = parseInt(
			_monthElem.options[_monthElem.selectedIndex].text,
			10
		);

		return { year, day, month };
	}

	private navigateToTimeShop(): void {
		const input = document.querySelector<HTMLInputElement>(
			".ageValidatedRedirect"
		);

		if (input) {
			location.assign(input.value);
		}
	}

	private openPdfForm(): void {
		const input = document.querySelector<HTMLInputElement>(
			".minorLinkRedirect"
		);

		if (input) {
			window.open(input.value, "_blank");
		}
	}
}

document.querySelectorAll<HTMLElement>(".ageValidationBtn").forEach(elem => {
	const popupBtn = new AgeValidationPopup(elem);

	popupBtn.addEventListeners();
});
