import { Component } from "../components/base/Component";
import { ICfa } from "../models/ICfa";
import { IComponentFactory } from "./IComponentFactory";

// Justification: Interface declarations define the properties available on an
// instance of an object, but we are interested in ensuring that a particular
// constructor exists, and since instances do not have constructors, no
// instance could satisfy the interface requirements.
// tslint:disable-next-line: interface-over-type-literal, no-any
type ComponentClass = new (element: HTMLElement, app: ICfa) => Component;

// Factory for auto-loading components. If the component class name is
// "MyComponent", place HTML into the page with attribute
// data-component="MyComponent" and this will auto load the component.
export class ComponentFactory implements IComponentFactory {
	private static readonly componentClasses = new Map<string, ComponentClass>();

	// The components that have been loaded by ComponentFactory
	private readonly _loadedComponents: Map<HTMLElement, Component> = new Map<
		HTMLElement,
		Component
	>();

	constructor(private readonly _pageContext: ICfa) {}

	// Registers a components class for auto-loading. A component can exist zero
	// or many times on a page.
	public static registerComponent(
		componentName: string,
		componentClass: ComponentClass
	): void {
		if (!componentName) {
			const message =
				"ComponentFactory.registerComponent: componentName cannot be empty";

			throw new Error(message);
		}

		if (ComponentFactory.componentClasses.has(componentName)) {
			const message =
				`ComponentFactory.registerComponent: ${componentName} is ` +
				"already defined";

			throw new Error(message);
		}

		ComponentFactory.componentClasses.set(componentName, componentClass);
	}

	public getComponent<T extends Component>(
		type: typeof Component,
		scope?: HTMLElement
	): T | null {
		const result = this.getAllComponents<T>(type, scope).next().value;
		if (result) {
			return result as T;
		}

		return null;
	}

	public *getAllComponents<T extends Component>(
		type: typeof Component,
		scope?: HTMLElement
	): IterableIterator<T> {
		if (scope) {
			for (const [element, component] of this._loadedComponents) {
				const isMatch =
					component instanceof type &&
					(scope === element || scope.contains(element));

				if (isMatch) {
					yield component as T;
				}
			}

			return;
		}

		for (const component of this._loadedComponents.values()) {
			if (component instanceof type) {
				yield component as T;
			}
		}
	}

	// This removes components from the factory's list of loaded components.
	// This is useful if you are about to remove those components from the DOM,
	// and you wish to remove any potential references to those same components,
	// so the browser's garbage collection can pick them up.
	public dereferenceComponents(scope: HTMLElement): void {
		[scope, ...scope.querySelectorAll("*")].forEach(node =>
			this._loadedComponents.delete(node as HTMLElement)
		);
	}

	// Loads components that have been registered with registerComponent within
	// the specified scope. If the scope is undefined, then loads components for
	// the entire page. This function can be used when additional components have
	// been added to the DOM after initial load.
	public async loadComponents(scope?: HTMLElement): Promise<Component[]> {
		let errorsEncountered = 0;
		let warningsEncountered = 0;
		const root = scope || document;

		const elements = Array.from(
			root.querySelectorAll("[data-component]")
		).filter(el => !this._loadedComponents.has(el as HTMLElement));

		console.groupCollapsed("Loading components");
		const components = elements
			.map(element => {
				const htmlElement = element as HTMLElement;
				const name = htmlElement.getAttribute("data-component");
				const fn = name ? ComponentFactory.componentClasses.get(name) : null;

				if (fn) {
					try {
						const component = new fn(htmlElement, this._pageContext);
						this._loadedComponents.set(htmlElement, component);
						console.log(name, component, htmlElement);

						return component;
					} catch (err) {
						errorsEncountered += 1;
						console.error(err);
					}
				} else if (name) {
					warningsEncountered += 1;
					console.warn("Unrecognized component", name, element);
				} else {
					warningsEncountered += 1;
					console.warn("Blank component", element);
				}
			})
			.filter(Boolean) as Component[];
		console.groupEnd();

		await Promise.all(
			components.map(component => {
				if (component.init) {
					try {
						return component.init();
					} catch (err) {
						errorsEncountered += 1;
						console.error(err);
					}
				}
			})
		);

		const warningCount =
			warningsEncountered === 0
				? ""
				: warningsEncountered === 1
				? "1 warning"
				: `${warningsEncountered} warnings`;

		const errorCount =
			errorsEncountered === 0
				? ""
				: errorsEncountered === 1
				? "1 error"
				: `${errorsEncountered} errors`;

		const totalCount = [errorCount, warningCount].filter(Boolean).join(" and ");

		if (errorsEncountered) {
			throw new Error(totalCount + " during component load");
		}

		if (warningsEncountered) {
			console.warn(totalCount + " during component load");
		} else {
			const len = components.length;
			const componentCount =
				len === 0
					? "0 components"
					: len === 1
					? "1 component"
					: len.toLocaleString() + " components";

			console.info(componentCount + " loaded");
		}

		return components;
	}
}
