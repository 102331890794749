import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class CardCarousel extends Component {
	public readonly carouselEl: JQuery;
	public carousel: JQuery | null = null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.carouselEl = $(".card-carousel");

		this.initCarousel();
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			centerMode: false,
			dots: true,
			focusOnChange: true,
			focusOnSelect: true,
			infinite: false,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			responsive: [
				{
					breakpoint: 320,
					settings: {
						arrows: false,
						centerMode: true,
						slidesToScroll: 1,
						slidesToShow: 1
					}
				},
				{
					breakpoint: 960,
					settings: {
						arrows: true,
						centerMode: false,
						slidesToScroll: 1,
						slidesToShow: 3
					}
				}
			],
			slidesToScroll: 1,
			slidesToShow: 3,
			useTransform: false
		};

		this.carousel = this.carouselEl.slick(carouselOptions);
	}
}

ComponentFactory.registerComponent("CardCarousel", CardCarousel);
