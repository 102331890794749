import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class LocationMenuCard extends Component {
	private readonly modalBtn: HTMLElement | null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.modalBtn = _element.querySelector("[data-element='modalCta']");

		if (this.modalBtn) {
			this.modalBtn.addEventListener("click", e => {
				e.preventDefault();
				this.handleOrderNowEvt();
			});
		}
	}

	private handleOrderNowEvt(): void {
		const modalId = "#location-order-now-modal";
		$.magnificPopup.close();
		$.magnificPopup.open({
			callbacks: {
				open: () => {
					setOrderLinks(this.modalBtn);
				}
			},
			items: {
				src: modalId
			},
			type: "inline"
		});
	}
}

ComponentFactory.registerComponent("locationMenuCard", LocationMenuCard);

function setOrderLinks(modalBtn: HTMLElement | null): void {
	const locationNumber = modalBtn?.dataset.locationNumber;
	const itemTag = modalBtn?.dataset.itemTag;
	const modal = document.getElementById("location-order-now-modal");

	if (!modal) {
		throw new Error("No Modal Element found.");
	}

	const pickupBtn = modal.querySelector("[data-element='pickupBtn']");
	const deliveryBtn = modal.querySelector("[data-element='deliveryBtn']");
	const redirectPickupUrl = pickupBtn?.getAttribute("data-redirect-url") || "";
	const redirectDeliveryUrl =
		deliveryBtn?.getAttribute("data-redirect-url") || "";
	let redirectUserToPickup = `${redirectPickupUrl}?locationNumber=${locationNumber}&itemTag=${itemTag}`;

	if (locationNumber === "" || locationNumber === undefined) {
		redirectUserToPickup = `${redirectPickupUrl}?itemTag=${itemTag}`;
	}

	pickupBtn?.setAttribute("href", redirectUserToPickup);
	deliveryBtn?.setAttribute("href", redirectDeliveryUrl);
}
