import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

class MembershipTierBenefits extends Component {
	private readonly chngBckgrdOnClick: Boolean;
	constructor(element: HTMLElement, app: ICfa) {
		super(element, app);
		const cpUser = document.querySelector<HTMLElement>(
			".nge-points__trophy__member-tier p:nth-child(2)"
		);
		let defaultTierToDisplay: string;
		const memTierInnerTxt = cpUser?.innerHTML.replace(/ /g, "-").toLowerCase();

		defaultTierToDisplay =
			!memTierInnerTxt?.startsWith("silver") &&
			!memTierInnerTxt?.startsWith("red") &&
			!memTierInnerTxt?.startsWith("signature")
				? "basic-member"
				: memTierInnerTxt;

		this.chngBckgrdOnClick = document.querySelector(".cfaOne-program") !== null;

		// Set Default Vals
		this.chngBannerColor(defaultTierToDisplay);
		this.toggleSelectedBenefitsDiv(defaultTierToDisplay);
		this.animateSelectedTier(defaultTierToDisplay);
		this.setLiClickEvents();
	}

	private setLiClickEvents(): void {
		this._element
			.querySelectorAll<HTMLElement>(".member-nav li")
			.forEach(item => {
				item.addEventListener("click", e => {
					e.preventDefault();
					const memberLinkClicked: string | undefined = item.dataset.memberLink;
					if (memberLinkClicked) {
						this.animateSelectedTier(memberLinkClicked);
						this.toggleSelectedBenefitsDiv(memberLinkClicked);
						if (this.chngBckgrdOnClick) {
							this.chngBannerColor(memberLinkClicked);
						}
					}
				});
			});
	}

	private animateSelectedTier(memberTierToShow: string): void {
		const elemSelector = $(
			".member-nav li[data-member-link='" + memberTierToShow + "']",
			this._element
		);
		const nonSelectedItems = $(
			".member-nav li[data-member-link!='" + memberTierToShow + "']",
			this._element
		);

		// change memberTier nav img if not already selected
		if (
			!elemSelector.find(".icon-wrapper img:nth-child(2)").hasClass("active")
		) {
			elemSelector.toggleClass("arrow_box");
			elemSelector.find(".icon-wrapper").toggleClass("selected");
			elemSelector.find(".icon-wrapper img:nth-child(2)").toggleClass("active");
		}

		nonSelectedItems.removeClass("arrow_box");
		nonSelectedItems.find(".icon-wrapper").removeClass("selected");
		nonSelectedItems.find(".icon-wrapper img:nth-child(1)").addClass("active");
		nonSelectedItems
			.find(".icon-wrapper img:nth-child(2)")
			.removeClass("active");
	}

	private toggleSelectedBenefitsDiv(memberTierToShow: string): void {
		const selector =
			".member-tier-benefits ul[class='" + memberTierToShow + "']";
		const inverse =
			".member-tier-benefits ul[class!='" + memberTierToShow + "']";

		$(inverse, this._element).hide();
		$(selector, this._element).fadeIn("slow");
	}

	private chngBannerColor(linkClicked: string): void {
		const memberNavElem = this._element.querySelector<HTMLElement>(
			".member-nav"
		);
		const memNavClasses = memberNavElem?.classList;
		const tierColorToShow = linkClicked.split("-")[0];

		if (memNavClasses && memberNavElem) {
			[...memNavClasses]
				.filter(r => r.endsWith("-bckgrd"))
				.forEach(r => memberNavElem.classList.remove(r));

			memNavClasses.add(tierColorToShow + "-bckgrd");
		}
	}
}

ComponentFactory.registerComponent(
	"MembershipTierBenefits",
	MembershipTierBenefits
);
