import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class GridLayout extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.setRowVariable();
	}

	setRowVariable(): void {
		return this._element.style.setProperty(
			"--row-number",
			JSON.stringify(this.gridChildren - 1)
		);
	}

	public get gridChildren(): number {
		return this._element.childElementCount;
	}
}

ComponentFactory.registerComponent("gridLayout", GridLayout);
