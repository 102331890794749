import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";
import { CustomEvent } from "../../../util/EventUtil";

export class MenuNav extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		if (window.innerWidth > this._app.layout.desktopWidth) {
			this.showDefaultSection();
		}
		this.handleOnScrollEvt();
		this.handleHomeMenuClick();
	}

	async init(): Promise<void> {
		const evtToDispatch = "subNavMenuCategoriesToDisplay";
		const evt = CustomEvent(evtToDispatch, {
			bubbles: true,
			detail: { menuCategoriesToDisplay: this.desktopMenuItems }
		});

		document.dispatchEvent(evt);
	}

	private showDefaultSection(): void {
		const hiddenClass = "hidden";
		$(".menu-group")
			.not("[data-is-initially-hidden='true']")
			.removeClass(hiddenClass);
	}

	private handleOnScrollEvt(): void {
		window.addEventListener(
			"scroll",
			animationDebounce(
				() => window.scrollY,
				scrollY => this.transitionOnScroll(scrollY)
			)
		);
	}

	private handleHomeMenuClick(): void {
		window.onhashchange = (e: Event) => {
			this.toggleMenuSection();
			this.navigateToSection();
		};
	}

	private navigateToSection(): void {
		const timeout = 500;
		$("html, body").animate(
			{
				scrollTop: 0
			},
			timeout
		);
	}

	private toggleMenuSection(): void {
		const menuSection = decodeURI(window.location.hash.substring(1));
		$(".menu-group").hide();

		$("h2#" + menuSection)
			.parents(".menu-group")
			.show()
			.removeClass("hidden");
	}

	private transitionOnScroll(scrollY: number): void {
		const menu = $("#menu");
		const homeServingsMenu = $(this._element);
		const homeMenuHeight = homeServingsMenu.outerHeight() || 0;
		const menuOffset = menu?.offset()?.top || 0;
		const calcDistance =
			menuOffset - homeMenuHeight - this._app.layout.navBaseHeight;
		const distance = calcDistance > 0 ? calcDistance : 0;

		const hasScrolledPastMenu = scrollY > distance;

		hasScrolledPastMenu
			? homeServingsMenu.addClass("fixed")
			: homeServingsMenu.removeClass("fixed");
	}

	get desktopMenuItems(): (object | null)[] {
		// tslint:disable-next-line: max-line-length
		const menuLinks: NodeListOf<HTMLAnchorElement> = this._element.querySelectorAll(
			"[data-element='subNavMenuCategory']"
		);

		return Array.from(menuLinks).map(link => ({
			href: link.querySelector("a")?.getAttribute("href"),
			imgSrc: link.querySelector("img")?.getAttribute("src"),
			innerText: link.querySelector("a")?.innerHTML
		}));
	}
}

ComponentFactory.registerComponent("menuNav", MenuNav);
