import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

interface IGeoLocationError {
	code: number;
	message: string;
}

export class GeoLocation extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.handleUseLocationBtn();
	}

	handleUseLocationBtn(): void {
		this._element.addEventListener("click", e => {
			e.preventDefault();
			if (navigator.geolocation) {
				const geoOptions = { timeout: 5000 };

				navigator.geolocation.getCurrentPosition(
					position => {
						this.addPositionCoordsToForm(position.coords);
						this.submitForm();
					},
					error => this.geoLocationError(error),
					geoOptions
				);
			} else {
				alert("The Browser does not support Geolocation");
			}
		});
	}

	private addPositionCoordsToForm({
		latitude,
		longitude
	}: {
		latitude: number;
		longitude: number;
	}): void {
		if (this.latitudeInput) {
			this.latitudeInput.value = JSON.stringify(latitude);
		}

		if (this.longitudeInput) {
			this.longitudeInput.value = JSON.stringify(longitude);
		}
	}

	private submitForm(): void {
		const form = this._element as HTMLFormElement;

		form.submit();
	}

	private get latitudeInput(): HTMLInputElement | null {
		return this._element.querySelector("#loclatitude");
	}

	private get longitudeInput(): HTMLInputElement | null {
		return this._element.querySelector("#loclongitude");
	}

	private geoLocationError(error: IGeoLocationError): void {
		const permissionErrorCode = 1;
		const timeoutErrorCode = 3;

		if (error.code === permissionErrorCode) {
			alert(
				"Your browser does not have permission to the geolocator. Enable service in your permissions."
			);
		}
		if (error.code === timeoutErrorCode) {
			alert("Geolocator service timed out");
		}
		throw new Error(`Received error type: ${error.code}, ${error.message}`);
	}
}

ComponentFactory.registerComponent("geoLocation", GeoLocation);
