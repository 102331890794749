import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { CustomEvent } from "../../../util/EventUtil";

export class NavToggle extends Component {
	static readonly ShowEvent = "NavToggle-Shown";
	static readonly HideEvent = "NavToggle-Hidden";

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		_element
			.querySelectorAll("[data-action='toggle nav']")
			.forEach(el =>
				el.addEventListener("click", evt => this.handleClick(evt))
			);
	}

	private handleClick(evt: Event): void {
		evt.preventDefault();
		this.toggleMobileNav();
	}

	private get anchor(): HTMLAnchorElement | null {
		return this._element.querySelector("a");
	}

	private showMobileNav(): void {
		const anchor = this.anchor;
		if (!anchor) {
			return;
		}

		anchor.setAttribute("aria-expanded", "true");
		anchor.classList.remove("icon-nav-toggle-off");
		anchor.classList.add("icon-close");

		// TODO: We should have a Layout component for the page layout structures.
		// And we should avoid changing elements that do not belong to
		// the NavToggle component. But code freeze is coming in a couple
		// of hours.
		document.body.classList.add("nav-shown");
		document.getElementById("stories")?.setAttribute("aria-hidden", "true");

		document
			.querySelectorAll(".frame")
			.forEach(el => el.setAttribute("aria-hidden", "true"));

		if (document.querySelector(".home")) {
			document.querySelector("#stories")?.classList.remove("teaser");
			$("header").css("top", "0");
		}

		const evt = CustomEvent(NavToggle.ShowEvent, { bubbles: true });
		this._element.dispatchEvent(evt);
	}

	private hideMobileNav(): void {
		const anchor = this.anchor;
		if (!anchor) {
			return;
		}

		anchor.setAttribute("aria-expanded", "false");
		anchor.classList.add("icon-nav-toggle-off");
		anchor.classList.remove("icon-close");

		// TODO: We should have a Layout component for the page layout structures.
		// And we should avoid changing elements that do not belong to
		// the NavToggle component. But code freeze is coming in a couple
		// of hours.
		document.body.classList.remove("nav-shown");
		document.getElementById("stories")?.setAttribute("aria-hidden", "false");
		document.getElementById("stories")?.classList.add("teaser");

		document
			.querySelectorAll(".frame")
			.forEach(el => el.setAttribute("aria-hidden", "false"));

		if (document.querySelector(".home")) {
			$("header").css("top", "50px");

			document
				.querySelectorAll(".header-frame")
				.forEach(el => el.classList.remove("home-lock"));
		}

		const evt = CustomEvent(NavToggle.HideEvent, { bubbles: true });
		this._element.dispatchEvent(evt);
	}

	private toggleMobileNav(): void {
		if (this.anchor?.classList.contains("icon-nav-toggle-off")) {
			this.showMobileNav();
		} else {
			this.hideMobileNav();
		}
	}
}

ComponentFactory.registerComponent("NavToggle", NavToggle);
