import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { animationDebounce } from "../../../util/AnimationDebounce";

export class PromoCta extends Component {
	private state: boolean;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.isTileImg ? this.applyTileImg() : this.applyBackgroundImg();
		this.state = this.isMobileView;
		this.addResizeEvent();
	}

	public applyBackgroundImg(): void {
		const img = this.isMobileView
			? this._element.querySelector("picture .sml-img")?.getAttribute("srcset")
			: // srcset not supported in IE
			  this._element.querySelector("picture img")?.getAttribute("src");

		$(this._element).css("background-image", `url("${img}")`);

		$(this._element).find("picture").hide();
	}

	private applyTileImg(): void {
		this._element.classList.add("tileImg");
	}

	private addResizeEvent(): void {
		window.addEventListener(
			"resize",
			animationDebounce(
				() => window.innerWidth,
				width => this.transitionOnResize(width)
			)
		);
	}

	private transitionOnResize(width: number): void {
		if (this.state !== this.isMobileView) {
			if (!this.isTileImg) {
				this.applyBackgroundImg();
			}
			this.state = this.isMobileView;
		}
	}

	get isMobileView(): boolean {
		return window.innerWidth < this._app.layout.desktopWidth;
	}

	get isTileImg(): boolean {
		return this._element.dataset.tileImg === "true";
	}
}

ComponentFactory.registerComponent("PromoCta", PromoCta);
