// Accordion (mobile) -> Tabs (desktop)
// Only supports one per page

class AccordTabs {
	private hash: string | null = null;

	constructor(private readonly tabsWrap: HTMLElement | null) {
		if (tabsWrap !== null) {
			// init tabs
			tabsWrap.querySelectorAll<HTMLElement>(".g-tabs__toggle").forEach(tab => {
				tab.addEventListener("click", e => {
					e.preventDefault();

					const hash = tab.dataset.hash;
					if (hash) {
						window.location.hash = hash;
					}

					// toggle visibility
					this.toggleTab(tab);
				});
			});

			// check for hashlinks
			this.checkHash();
		}
	}

	private activateFirstTab(): void {
		const element = this.tabsWrap;
		if (!element) {
			throw new Error("Could not locate tabs");
		}

		const tab1Desktop = element.querySelector<HTMLElement>(
			".g-tabs__toggle--desktop"
		);

		if (!tab1Desktop) {
			throw new Error("Could not locate desktop tab");
		}

		const tab1Mobile = element.querySelector<HTMLElement>(
			".g-tabs__toggle--mobile"
		);

		// there are 2 tabs a mobile and a desktop
		setTimeout(() => {
			if ($(tab1Desktop).css("display") === "block") {
				this.toggleTab(tab1Desktop);
			} else if (tab1Mobile) {
				this.toggleTab(tab1Mobile);
			}
		});
	}

	private checkHash(): void {
		this.hash = window.location.hash;

		if (this.hash) {
			const targetLabel = this.hash.split("#")[1];
			const allToggles = $(`.g-tabs__toggle[data-hash="${targetLabel}"]`);
			let targetToggle = allToggles[0];

			// there are 2 tabs a mobile and a desktop
			if ($(targetToggle).css("display") !== "block") {
				targetToggle = allToggles[1];
			}

			// show target tab
			setTimeout(() => this.toggleTab(targetToggle));
		} else {
			// show first tab on land
			this.activateFirstTab();
		}
	}

	private toggleTab(tab: HTMLElement): void {
		const element = this.tabsWrap;
		if (!element) {
			throw new Error("Could not locate tabs");
		}

		// toggle content
		const activeContentClass = "g-tabs__content--active";
		const activeTabClass = "g-tabs__toggle--active";
		const tabContent = document.getElementById(tab.dataset.tabtarget || "");
		const lastContent = element.querySelector(`.${activeContentClass}`);
		const lastTab = element.querySelector(`.${activeTabClass}`);
		const isMobileToggle = tab.classList.contains("g-tabs__toggle--mobile");
		const animationIn = isMobileToggle ? "slideDown" : "fadeIn";
		const animationOut = isMobileToggle ? "slideUp" : "fadeOut";
		const animationSpeed = isMobileToggle ? "fast" : 0;

		// if not active toggle
		if (!tab.classList.contains(activeTabClass)) {
			// toggle tab active class
			tab.setAttribute("aria-expanded", "true");
			tab.classList.add(activeTabClass);

			// if there is a previously opened tab
			if (lastTab !== null) {
				lastTab.setAttribute("aria-expanded", "false");
				lastTab.classList.remove(activeTabClass);

				// toggle content
				if (lastContent) {
					$(lastContent)[animationOut](animationSpeed, () => {
						lastContent.classList.remove(activeContentClass);
					});
				}
			}

			if (tabContent) {
				$(tabContent)[animationIn](animationSpeed, () => {
					tabContent.classList.add(activeContentClass);
				});
			}
		} else if (isMobileToggle) {
			// only when accordions on mobile, allow content to close if already open
			if (lastTab) {
				lastTab.setAttribute("aria-expanded", "false");
				lastTab.classList.remove(`${activeTabClass}`);
			}

			// toggle content
			if (lastContent) {
				$(lastContent).slideUp("fast", () => {
					lastContent.classList.remove(`${activeContentClass}`);
				});
			}
		}
	}
}

// init Class
const tabWrap = document.getElementById("g-tabs");
const accordTabs = new AccordTabs(tabWrap);
