import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

function extractTemplate(context: HTMLElement, selector: string): string {
	const template = context.querySelector<HTMLTemplateElement>(selector);
	const html = template?.innerHTML ?? "";
	template?.parentElement?.removeChild(template);

	return html;
}

export class FadeCarousel extends Component {
	public readonly carouselEl: JQuery;
	public carousel: JQuery | null = null;
	private readonly isInitialized: boolean = false;
	private readonly carouselSlides: NodeListOf<HTMLElement> | null;

	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.carouselSlides = _element.querySelectorAll<HTMLElement>(
			".carousel-item"
		);

		this.carouselEl = $(".fade-carousel");
		// .addEventListener("hashchange", e => this.checkHash());

		this.initCarousel();
	}

	initCarousel(): void {
		const carouselOptions: JQuerySlickOptions = {
			accessibility: false,
			cssEase: "linear",
			dots: false,
			fade: true,
			infinite: true,
			mobileFirst: true,
			nextArrow: extractTemplate(this._element, "template[data-next-arrow]"),
			prevArrow: extractTemplate(this._element, "template[data-prev-arrow]"),
			slidesToShow: 1,
			speed: 500,
			useTransform: false
		};

		$(".g-tabs__toggle--desktop").on("click", () => {
			this.carousel = this.carouselEl.slick(carouselOptions);
		});
	}
}

ComponentFactory.registerComponent("FadeCarousel", FadeCarousel);
