// * core-js provides polyfills for older browsers. This is a magic import.
//   Babel will read the .browserslistrc file for a list of browsers that we
//   are currently supporting, and include only the polyfills that we need.
//
// * regenerator-runtime additionally provides async/await support.

// tslint:disable: no-submodule-imports
// Justification: core-js documentation says to import the submodule.

import "core-js/stable";
import "regenerator-runtime/runtime";
import "../js/legacy/cfacom/account";
import "../js/legacy/cfacom/analytics";
import "../js/legacy/cfacom/cp";
import "../js/legacy/cfacom/custom";
import "../js/legacy/cfacom/listenlovelearn";
import "../js/legacy/cfacom/lll";
import "../js/legacy/cfacom/main";
import "../js/legacy/cfacom/rt-renderings";
import "../js/legacy/cfacom/stories";
import "./areas/canada/CanadaLocationsCardGrid";
import "./areas/common/Accordion/Accordion";
import "./areas/common/Carousels/CardCarousel";
import "./areas/common/Carousels/FadeCarousel";
import "./areas/common/IsSticky/IsSticky";
import "./areas/common/ViewMore/ViewMore";
import "./areas/common/Carousels/SimpleCarousel";
import "./areas/common/DeliveryHero/DeliveryHero";
import "./areas/common/Facts/Facts";
import "./areas/common/FooterModuleCallout/FooterModuleCallout";
import "./areas/common/GeoLocation/GeoLocation";
import "./areas/common/GridLayout/GridLayout";
import "./areas/common/GridLayout/GridView";
import "./areas/common/Heroes/BCVideoModule";
import "./areas/common/InformationBar/InformationBar";
import "./areas/common/MainNav/LocationTools";
import "./areas/common/MainNav/MainNav";
import "./areas/common/MainNav/NavToggle";
import "./areas/common/MainNav/YextSearch";
import "./areas/common/ObjectFitPolyfill/ObjectFitPolyfill";
import "./areas/common/PromoCta/PromoCta";
import "./areas/common/PWOHero/OrderDeliveryLink";
import "./areas/common/PWOModal/PWOSearchForm";
import "./areas/common/SearchForm/SearchForm";
import "./areas/common/SetCookie/SetCookie";
import "./areas/common/SocialShare/SocialShare";
import "./areas/covid/QuestionsFilterAccordion";
import "./areas/covid/SlidedownAnimation";
import "./areas/covid/StickyNavDropdown/StickyNav";
import "./areas/delivery/DeliveryPartnerList";
import "./areas/delivery/LocationModule";
import "./areas/franchise/BreadcrumbsNav";
import "./areas/gifting/CopyToClipboard";
import "./areas/gifting/ReceiveGiftCallout";
import "./areas/legacy/AccordTabs";
import "./areas/legacy/AccordToggleEffect";
import "./areas/legacy/AgeValidationPopup";
import "./areas/legacy/BrightcoveVideo";
import "./areas/legacy/CarouselGrid";
import "./areas/legacy/GridMobileSlickSlider";
import "./areas/legacy/ImgTxtCarousal";
import "./areas/legacy/InformBanner";
import "./areas/legacy/LineClamp";
import "./areas/legacy/NgeMembershipTier";
import "./areas/legacy/ProgressBarWidthAnim";
import "./areas/legacy/ScrollContent";
import "./areas/legacy/ScrollToElement";
import "./areas/legacy/SuccessFailToggleDisplay";
import "./areas/legacy/ThumbnailBcGallery";
import "./areas/legacy/TypeWriterAnimation";
import "./areas/legacy/UpdateCharacterCount";
import "./areas/location/GOpeningZipcodeCheck";
import "./areas/common/Menu/AllergensFilter";
import "./areas/common/Menu/LocationMenuCard";
import "./areas/common/Menu/LocationMenuHero";
import "./areas/common/MenuNav/MenuNav";
import "./areas/common/MenuNav/MenuNavMobile";
import "./areas/common/Menu/MenuBannerAlert";
import "./areas/common/Menu/PdpPwoCta";
import "./areas/olo/OloRadio";
import "./areas/olo/OLOReviewOrderForm";
import "./areas/olo/RadioSelection";
import "./areas/one/OneSurvey";
import "./areas/rewards/Reward";
import "./areas/stcBrands/StcDonationForm";
import "./polyfills/Element.prototype.closest";
import "./polyfills/Element.prototype.matches";

export { CfaContext } from "./app/CfaContext";
export { DeliveryHero } from "./areas/common/DeliveryHero/DeliveryHero";
export { ReceiveGiftGifterIsGifteeModal } from "./areas/gifting/ReceiveGiftGifterIsGifteeModal";
export { ReceiveGiftQrModal } from "./areas/gifting/ReceiveGiftQrModal";
export { ReceiveGiftRewardAddedModal } from "./areas/gifting/ReceiveGiftRewardAddedModal";
export { ReceiptHighlighter } from "./areas/legacy/ReceiptHighlightEffect";
export { NutritionAccordion } from "./areas/olo/NutritionAccordion";
export { OloDesserts } from "./areas/olo/OloDesserts";
export { OneSurvey } from "./areas/one/OneSurvey";
export { Cookie } from "./util/Cookie";
export { LocalStorage } from "./util/LocalStorage";
export { Validation } from "./util/Validation";
