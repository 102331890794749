$(function () {
	// ADA: To support enter key for all html elements that have role="button"
	$(function () {
		$(".co-item").find("a[title='Read More']").removeAttr("title");
		// Add keypress event on every link where exist role="button"
		$(document).on(
			"keypress",
			'[role="button"]',
			null,
			onKeypressRoleButton,
			false
		);
		// DatePicker ADA Fix
		$("#TimeOfVisitDate")
			.datepicker()
			.keydown(function (e) {
				e.preventDefault();
				//   TAB: 9
				//  LEFT: 37
				//    UP: 38
				// RIGHT: 39
				//  DOWN: 40
				//             IE        OTHER
				var code = e.keyCode || e.which;
				// If key is not TAB
				if (code != "9") {
					// And arrow keys used "for performance on other keys"
					if (code == "37" || code == "38" || code == "39" || code == "40") {
						// Get current date
						var parts = $(this).val().split("/");
						var currentDate = new Date(parts[2], parts[0] - 1, parts[1]);
						// Show next/previous day/week
						switch (code) {
							// LEFT, -1 day
							case 37:
								currentDate.setDate(currentDate.getDate() - 1);
								break;
							// UP, -1 week
							case 38:
								currentDate.setDate(currentDate.getDate() - 7);
								break;
							// RIGHT, +1 day
							case 39:
								currentDate.setDate(currentDate.getDate() + 1);
								break;
							// DOWN, +1 week
							case 40:
								currentDate.setDate(currentDate.getDate() + 7);
								break;
						}
						// If result is ok then write it
						if (currentDate != null) {
							$(this).datepicker("setDate", currentDate);
						}
					} else {
						return false;
					} // If other keys pressed.. return false
				}
			});
		// carousel slider effect
		if ($(".story-image-carousel")[0]) {
			$(".slick-slide").each(function (i) {
				var slide_src_url = $(this).find("img").prop("src");

				var CarImgWidth = $(this).find("img").width();
				var CarImgHeight = $(this).find("img").height();

				var CarImgAspectRatio = CarImgWidth / CarImgHeight;

				if (CarImgAspectRatio.toPrecision(2) <= 1) {
					$(this).prepend(
						$(
							'<div class="slide-img-container" id="silde_image_' +
								i +
								'"><div class="bg" id="bg_img_' +
								i +
								'"></div><div class="img__wrap"><img id="silde_img_' +
								i +
								'" src="' +
								slide_src_url +
								'" /></div></div>'
						)
					);
					if (!$(".story-image-carousel").hasClass("slide-img-container")) {
						$(".slide-img-container .bg").each(function () {
							$("#bg_img_" + i).css(
								"background-image",
								"url(" +
									$(".slick-slide #silde_image_" + i)
										.siblings("img")
										.prop("src") +
									")"
							);
							$(".slick-slide #silde_image_" + i)
								.siblings("img")
								.hide();
						});
					}
				}
			});
		}

		//Go find all instances of video-panel-image-overlay on a page
		var instancesOfVideoPanelOnPage = document.getElementsByClassName(
			"video-panel-container"
		);

		if (instancesOfVideoPanelOnPage) {
			for (var i = 0; i < instancesOfVideoPanelOnPage.length; i++) {
				var iframe = $(instancesOfVideoPanelOnPage[i]).children("iframe")[0],
					staticImage = $(instancesOfVideoPanelOnPage[i]).children(
						".image-overlay"
					)[0],
					playButton = $(instancesOfVideoPanelOnPage[i]).children(".play")[0],
					player = "player" + i,
					videoId = instancesOfVideoPanelOnPage[i].getAttribute("data-videoId");

				//Following attributes to set on Video Panel
				iframe.setAttribute(
					"src",
					"https://www.youtube.com/embed/" +
						videoId +
						"?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large"
				);

				iframe.setAttribute("onload", "player" + i + "=new YT.Player(this)");

				if (staticImage) {
					staticImage.setAttribute("onclick", "player" + i + ".playVideo()");

					playButton.setAttribute("onclick", "player" + i + ".playVideo()");
				}
			}
		}

		$(".video-panel-image-overlay").click(function () {
			$(this).children(".image-overlay").css("display", "none");
			$(this).children(".play").css("display", "none");
		});

		// <Nutrients & Allergens>

		var toggleSwitchContainer = document.getElementById("view-toggle");
		var toggleSwitch = document.getElementById("toggle-off");
		var toggleSwitchOn = document.getElementById("toggle-on");
		var allergensTable = $(".allergens-table");
		var nutrientsTable = $(".nutrients-table");
		var disclaimerTxt = $(".disclaimer-txt");

		// Nutrients and Allergens click on label, input is clicked
		$("#view-toggle label").click(function () {
			var labelID = $(this).attr("for");
			$("#" + labelID).trigger("click");
		});

		//Add keypress event for ADA consideration
		$("#view-toggle label").on("keypress", function (e) {
			if (e.which == 13) {
				$(this).trigger("click");
			}
		});

		//Nutrients and Allergens toggle btw Nutrients/Allergens
		$(toggleSwitchContainer).click(function (e) {
			//change to allergens view
			if (toggleSwitch.checked) {
				nutrientsTable.addClass("no-display");
				allergensTable.removeClass("no-display");
				disclaimerTxt.removeClass("no-display");
				toggleSwitch.setAttribute("aria-checked", "true");
				toggleSwitchOn.setAttribute("aria-checked", "false");
				toggleSwitch.focus;

				$("#nutrition-table-anchors a").each(function () {
					var href = "#" + $(this).attr("data-id") + "-allergen";
					$(this).attr("href", href);
				});
				//change to nutrients view
			} else {
				nutrientsTable.removeClass("no-display");
				allergensTable.addClass("no-display");
				disclaimerTxt.addClass("no-display");
				toggleSwitch.setAttribute("aria-checked", "false");
				toggleSwitchOn.setAttribute("aria-checked", "true");

				$("#nutrition-table-anchors a").each(function () {
					var href = "#" + $(this).attr("data-id") + "-nutrition";
					$(this).attr("href", href);
				});
			}
		});

		//Tooltip text, on enter, show hidden text div
		$(".tooltip-block a").keydown(function (e) {
			if (e.which == 13) {
				$(".txt-wrapper").addClass("display");
				setTimeout(function () {
					$(".txt-wrapper").removeClass("display");
				}, 20000);
			}
		});

		//</Nutrients & Allergens>
	});

	// Add favorite location
	$("body").on("click", ".favorite-restaurant", function (e) {
		e.preventDefault();

		var locationId = $(this).attr("data-loc-id");
		var url =
			"/OnlineOrdering/OloAccount/AddFavoriteRestaurant?locationNumber=" +
			locationId;
		$.ajax({
			url: url,
			type: "POST",
			cache: false
		}).done(function () {
			var labelOrHeart = $(".favorite-restaurant");
			$.each(labelOrHeart, function () {
				var obj = $(this);
				if (obj.hasClass("heart-icon")) {
					obj.removeClass("heart-icon").addClass("heart-icon-red");
				} else {
					obj.text("Favorited restaurant (remove)");
				}
				obj
					.removeClass("favorite-restaurant")
					.addClass("delete-favorite-restaurant");
			});
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "setmyfavoriterestaurant"
		});
	});

	// Delete favorite location
	$("body").on("click", ".delete-favorite-restaurant", function (e) {
		e.preventDefault();

		var locationId = $(this).attr("data-loc-id");
		var url =
			"/OnlineOrdering/OloAccount/DeleteFavoriteRestaurant?locationNumber=" +
			locationId;
		$.ajax({
			url: url,
			type: "POST",
			cache: false
		}).done(function () {
			var labelOrHeart = $(".delete-favorite-restaurant");
			$.each(labelOrHeart, function () {
				var obj = $(this);
				if (obj.hasClass("heart-icon-red")) {
					obj.removeClass("heart-icon-red").addClass("heart-icon");
				} else {
					obj.text("Set as my favorite restaurant");
				}
				obj
					.removeClass("delete-favorite-restaurant")
					.addClass("favorite-restaurant");
			});
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "deletemyfavoriterestaurant"
		});
	});

	//ADA Fix
	function onKeypressRoleButton(e) {
		//Need both, 'keyCode' and 'which' to work in all browsers.
		var code = e.keyCode || e.which,
			enterKey = 13,
			spaceKey = 32;
		//If user press enter or space key:
		if (code === enterKey || code === spaceKey) {
			// Prevent the default action to stop scrolling when space is pressed
			e.preventDefault();

			//Do same thing as onclick:
			$(e.currentTarget).click();
			$(e.currentTarget).focus();
		}
	}

	// Add a class for devices just in case need to target for any purpose
	var platformAndBrowser = navigator.userAgent;
	if (platformAndBrowser.match(/iPad/i)) {
		$("body").addClass("ipad");
	} else if (platformAndBrowser.match(/iPhone/i)) {
		$("body").addClass("iphone");
	} else if (platformAndBrowser.match(/Android/i)) {
		$("body").addClass("android");
	}

	var expEditor = $(".hdnExpEditor").val() === "true";

	// Mobile Device Functions
	ssm.addState({
		id: "mobile",
		query: "(max-width: 959px)",
		onEnter: function () {
			// Mobile lock classif (!$('.home')[0]){
			$(window).scroll(function () {
				if (!$("#stories.shown")[0]) {
					if ($(this).scrollTop() > 100) {
						$(".header-frame").addClass("m-lock");
						$("#stories").removeClass("teaser");
						$("header").css("top", "0");
					} else {
						$(".header-frame").removeClass("m-lock");
						if ($(".home-lock")[0]) {
							$("#stories").addClass("teaser");
						}
					}
				}
			});
			// Bring User up when Showing Nutritional Overlay
			$(".show-overlay").click(function () {
				$("html, body").animate({ scrollTop: 0 }, 800);
			});
			// Lead Story Image
			$(".lead-story, .product-banner").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".sml-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(".slide-bkgd").hide();
			});
			// Editorial Carousel
			$("#stories .stories").slick({
				arrows: true
			});

			// Screen reader implementation

			$(".frame").attr("aria-hidden", "false");
			$("#stories").attr("aria-hidden", "false");

			$(".main-nav .icon-arrow-down").click(function (e) {
				$(this).closest("li").toggleClass("active");
				if (
					$(this).attr("aria-expanded") !== "true" ||
					$(this).parent("a").attr("aria-disabled") == "true"
				) {
					$(this).attr("aria-expanded", "true");
					$(this).parent("a").attr("aria-disabled", "false");
					$(this).closest("li").find(".subnav").css("display", "block");
				} else {
					$(this).attr("aria-expanded", "false");
					$(this).parent("a").attr("aria-disabled", "true");
					$(this).closest("li").find(".subnav").css("display", "none");
				}
				e.preventDefault();
			});
			// Show Location Search
			$(".change, .menu-hero-edit").click(function (e) {
				$(".location-tools").addClass("location-tools-open");
				$(".location-search-frame").show();
				$("#location-search").focus().select();
				$(".current-location, .nav-toggle").hide();
				if ($("body").hasClass("nav-shown")) {
					toggleMobileNav();
				}
				e.preventDefault();
			});
			$(".location-search-frame .icon-close").click(function (e) {
				$(".location-tools").removeClass("location-tools-open");
				$(".location-search-frame").hide();
				$(".current-location, .nav-toggle").show();
				e.preventDefault();
			});
			// Homepage menu items on mobile
			if (!expEditor) {
				$(".home .menu-items, .home .co-item").hide();
			}
			$(".menu-group h2").on("click", function (e) {
				if (!$(this).parent().hasClass("no-accordion")) {
					if (
						$(this)
							.parents(".menu-group")
							.children(".menu-items")
							.is(":visible")
					) {
						$(this)
							.parents(".menu-group")
							.children()
							.not(".title-container")
							.slideUp();
						$(this).removeClass("active");
						$(this).attr("aria-expanded", "false");
					} else {
						$(".menu-group h2").removeClass("active");
						$(".menu-group h2").attr("aria-expanded", "false");
						$(".menu-group").children().not(".title-container").slideUp();
						$(this)
							.parents(".menu-group")
							.children()
							.not(".title-container")
							.slideDown();
						$(this).addClass("active");
						$(this).attr("aria-expanded", "true");
					}
					$("html, body").animate(
						{
							scrollTop: $("#menu").offset().top + -80
						},
						500
					);
				}
				e.stopPropagation();
			});
			// Sticky Headers on mobile
			$(".fullsize.shown").css("height", "auto");
			// Mini sitemap
			$(".no-results-sitemap .icon-arrow-down").click(function () {
				$(this).closest("ul").toggleClass("active");
			});
			// Apply background image to hero banners
			if (!$("body").hasClass("sc-editor")) {
				$(".top-hero").each(function () {
					$(this).css(
						"background-image",
						"url(" + $(".top-hero .sml-img").prop("src") + ")"
					);
					$(".hero-img").hide();
				});
				$(".top-hero.bio").each(function () {
					$(".top-hero.bio .lrg-img").hide();
				});
				$(".truett-memorial").each(function () {
					$(this).css(
						"background-image",
						"url(" + $(".truett-img .sml-img").prop("src") + ")"
					);
					$(".truett-img").hide();
				});
			}
			// Turn companion items displayed on PDP into carousel on mobile
			$(".companions").slick({
				arrows: true,
				dots: false,
				touchMove: true,
				draggable: true,
				swipe: true
			});
			// History
			$(".history-module").slice(2).hide();
			$(".history .show-history").click(function (e) {
				$(".history-module").show();
				$(this).hide();
				e.preventDefault();
			});
			// Truett's Legacy
			$(".see-legacy").click(function (e) {
				$(".co-about.legacy").show();
				$(this).hide();
				e.preventDefault();
			});
			//  FAQ Toggling Effect
			if ($(".contact-support")[0]) {
				if (window.location.hash != "") {
					var selectFAQ = window.location.hash.substring(1);
					if ($("dl.faq#" + selectFAQ)[0]) {
						$('a[href$="' + selectFAQ + '"]')
							.parent("li")
							.addClass("current");
						$("dl.faq")
							.not("#" + selectFAQ)
							.hide();
						$("html, body").animate(
							{
								scrollTop: $("#faq").offset().top - +75
							},
							500
						);
					} else {
						$("dl.faq").hide();
					}
				} else {
					$("dl.faq").hide();
				}
				$(".toggle").on("click", function () {
					if ($(this).hasClass("active")) {
						$(this).find("span").attr("aria-expanded", "false");
						$(this).removeClass("active");
						$(this).nextUntil(".toggle").hide();
					} else {
						$("dl.faq").hide();
						$(".toggle.active").removeClass("active");
						$(".toggle").find("span").attr("aria-expanded", "false");
						$(this).addClass("active");
						$(this).nextUntil(".toggle").show();
						$(".toggle.active").find("span").attr("aria-expanded", "true");
					}
				});
			}

			//Footer hero
			$(".lrg-app-banner").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".sml-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("background-position-y", "initial");
				$(".lrg-app-banner .app-banner-image").hide();
			});
			// Price Info tooltop
			$(".product-focus .icon-info").click(function () {
				$(this).children(".tooltip").show();
				setTimeout(function () {
					$(".tooltip").hide();
				}, 2000);
			});

			//Nutrients/Allergens Mobile - Underline Nav Effect
			if ($(".nutrition-allergens-table").length > 0) {
				//show breakfast class on open
				$('a[href="#breakfast-nutrition"]').addClass("selected");
				$(".home-menu li a").click(function (e) {
					e.preventDefault();
					var target = $(this).attr("href"); // Set the target as variable
					var navHeight = $(".header-frame header").outerHeight();
					var offset;

					if ($(".home-menu").hasClass("lock")) {
						offset = -185;
					} else {
						offset = -375;
					}
					jumpToNutrientsLink(target, offset);
				});
				//extend y-axis to allow table to overflow
				$(".frame main").addClass("overflow-y-enable");

				//click on tooltip on mobile to show
				$(".tooltip-link").click(function () {
					$(this)
						.find("span.tooltiptext")
						.addClass("visible")
						//rmv after 5 secs
						.delay(7000)
						.queue(function (next) {
							$(this).removeClass("visible");
							next();
						});
				});

				$(window).scroll(function () {
					stickyNutriAllergNavBar();
				});
			}
			// 404 animation
			$.fn.rotate = function (degrees) {
				$(this).css({
					"-webkit-transform": "rotate(" + degrees + "deg)",
					"-moz-transform": "rotate(" + degrees + "deg)",
					"-ms-transform": "rotate(" + degrees + "deg)",
					transform: "rotate(" + degrees + "deg)"
				});
				return $(this);
			};

			//404 Hoof Animation
			$(function () {
				if ($("._404").length == 0) {
					//do nothing if this is not a 404 page.
					return;
				}
				var speed = 2000;
				var delayOnLoad = 2000;
				var width = $(window).width();
				$(".frame").css("overflow", "hidden");
				$(
					"._404 .no-results-top, ._404 .no-results-top .wrapper, ._404 .no-results-top .wrapper .content"
				).css("position", "initial");
				var note = $(".sticky-note");
				var hoof = $(".hoof");
				var frame = $(".frame");
				var noteWidth = note.width();
				var noteHeight = note.height();
				var hoofWidth = hoof.width();
				var hoofHeight = hoof.height();
				var frameWidth = frame.width();
				var noteTopFinish = 80;
				var hoofTopFinish = -150;
				var noteRightFinish;
				var hoofRightFinish;
				var reInitRightFinish = function (w) {
					noteRightFinish = w / 2 - noteWidth / 2;
					hoofRightFinish = w / 2 - hoofWidth / 2 - noteWidth + 50;
				};
				reInitRightFinish(width);
				var startDiff = noteTopFinish + noteHeight;
				var noteTopStart = noteTopFinish - startDiff;
				var noteRightStart = noteRightFinish - startDiff;
				var hoofTopStart = hoofTopFinish - startDiff;
				var hoofRightStart = hoofRightFinish - startDiff;
				hoof.rotate(-15);
				note.css("left", "auto");
				note.css("top", noteTopStart + "px");
				note.css("right", noteRightStart + "px");
				note.css("display", "block");
				hoof.css("top", hoofTopStart + "px");
				hoof.css("right", hoofRightStart + "px");
				hoof.css("display", "block");
				var top = 0 + noteHeight;
				var right = noteWidth;
				var updateIfNeeded = function () {
					setInterval(function () {
						var currentWidth = $(window).width();
						if (currentWidth != width) {
							width = currentWidth;
							reInitRightFinish(width);
							note.css("right", noteRightFinish + "px");
							hoof.css("right", hoofRightFinish + "px");
						}
					}, 500);
				};
				var animate = function () {
					note.animate(
						{
							top: "+=" + (noteTopFinish - noteTopStart),
							right: "+=" + (noteRightFinish - noteRightStart)
						},
						speed,
						function () {
							var pItems = $(".content p");
							$(pItems.get(0)).css("visibility", "hidden");
							$(pItems.get(1)).css("visibility", "hidden");

							updateIfNeeded();
						}
					);
					hoof.animate(
						{
							top: "+=" + (hoofTopFinish - hoofTopStart),
							right: "+=" + (hoofRightFinish - hoofRightStart)
						},
						speed,
						function () {
							hoof.animate(
								{
									top: "-=" + (hoofTopFinish - hoofTopStart),
									right: "-=" + (hoofRightFinish - hoofRightStart)
								},
								2000,
								function () {}
							);
						}
					);
				};
				setTimeout(function () {
					animate();
				}, delayOnLoad);
			});
			// Mobile expandable content block
			$(".expandable-toggle").click(function (e) {
				$(this).prev(".desktop-only").toggle();
				$(this).toggleClass("active");
			});
			$(".icon-close").click(function () {
				$(".slick-prev, .slick-next ").show();
			});

			// About Pages apply background - Mobile
			$(".truett-intro").each(function () {
				var theImage = $(this).find("picture .sml-img");
				$(this).css("background-image", "url(" + $(theImage).prop("src") + ")");
				$(this).find("picture").hide();
			});

			// Blue social bar on stories
			if ($(".story-article .cfacom_cw").length === 1) {
				var element = $(".story-start .tag");
				var distance = element.offset().top;
				var divHeight = $(".story-top").height();
				var $window = $(window);
				$window.scroll(function () {
					if ($window.scrollTop() >= distance) {
						$(".story-top").addClass("locked");
						$(".story-article .content").css("padding-top", divHeight);
					} else {
						$(".story-top").removeClass("locked");
						$(".story-article .content").css("padding-top", "0");
						$(".social").removeClass("slideout");
						$(".social-icons.default").show();
						$(".social-icons.alt").hide();
					}
				});
			}
			// Fullsize Div
			//if (!expEditor) {
			$(window).resize(function () {
				var windowHeight = $(window).height();
				var theHeight = windowHeight - 136;
				var storyHeight = windowHeight - 115;
				var nutritionHeight = windowHeight;
				$(".fullsize.nutrition-details").css("height", nutritionHeight);
				$(".story-landing .lead-story, .product-banner").css(
					"height",
					storyHeight
				);
				var siblingsHeight = 0;
				$(".co-content")
					.siblings()
					.each(function (index) {
						siblingsHeight += $(this).outerHeight();
					});

				if ($(".product-page")[0]) {
					$(
						".product-focus.fullsize, .product-focus.fullsize .co-content, .product-focus.fullsize .food-item"
					).css("height", "auto");
				}
			});
			$(window).resize();

			// Hide CFAOne Promo piece
			$(window).on("load", function () {
				$(".cfa1-promo").addClass("open");
				$(".cfa1-promo .icon-close").on("click", function () {
					$(".cfa1-promo").removeClass("open");
				});
			});
			$(function () {
				setTimeout(function () {
					if ($(".story-image-carousel")[0]) {
						$(".slick-slide").each(function (i) {
							var CarImgHeight = $(this).find("img").height();
							var carouselArrowsTop = CarImgHeight / 2;
						});
					}
				}, 500);
			});
		},
		onLeave: function () {
			if (!expEditor) {
				location.reload();
			}
			$(".menu-items, .co-item").show();
			$(".menu-group h2").removeClass("active");
		}
	});

	// Desktop Functions
	ssm.addState({
		id: "non-mobile",
		query: "(min-width: 960px)",
		onEnter: function () {
			// Main Nav
			//if (!expEditor) {
			// Fullsize Div
			$(window).resize(function () {
				var windowHeight = $(window).height();
				var theHeight = windowHeight - 136;
				var storyHeight = windowHeight - 115;
				$(".lead-story, .product-banner").css("height", storyHeight);
				var siblingsHeight = 0;
				$(".co-content")
					.siblings()
					.each(function (index) {
						siblingsHeight += $(this).outerHeight();
					});
			});
			$(window).resize();

			if ($(".about")[0]) {
				var windowHeight = $(window).height();
				var theHeight = windowHeight - 66;
				var theMenu = $("#menu");
				$(".main-nav #navitem-about .subnav").slideDown("slow");
				$(".stories").css("height", theHeight);
				var storiesHeight = $(".stories").height();
				var storiesScroll = storiesHeight - 70;
				$(
					".main-nav #navitem-menu .subnav, .main-nav #navitem-stories .subnav"
				).mouseleave(function () {
					$(
						".main-nav #navitem-menu .subnav, .main-nav #navitem-stories .subnav"
					).fadeOut();
					$(".main-nav #navitem-about .subnav").fadeIn();
				});
				$(window).scroll(function () {
					if (
						$(this).scrollTop() >
						storiesHeight + $("#scPageExtendersForm").height()
					) {
						$(".header-frame").addClass("lock");
						$(".nav-toggle").addClass("active");
						$(".navitem-hoverable a").addClass("off-screen");
						$(".main-nav #navitem-about .subnav").slideUp("slow");
						$(".main-nav #navitem-menu .subnav").slideUp("slow");
						$(".main-nav #navitem-stories .subnav").slideUp("slow");
					} else {
						$(".header-frame").removeClass("lock");
						$(".nav-toggle").removeClass("active");
						$(".navitem-hoverable a").removeClass("off-screen");
						$(".main-nav #navitem-about .subnav").slideDown("slow");
					}
				});
				$(".nav-frame").mouseenter(function () {
					$(".header-frame").addClass("focused");
				});
				$(".nav-frame").mouseleave(function () {
					$(".header-frame").removeClass("focused");
				});
			} else if ($(".story-landing")[0]) {
				var windowHeight = $(window).height();
				var theHeight = windowHeight - 66;
				var theMenu = $("#menu");
				$(".main-nav #navitem-stories .subnav").slideDown("slow");
				$(".stories").css("height", theHeight);
				var storiesHeight = $(".stories").height();
				var storiesScroll = storiesHeight - 70;
				$(".stories-feature").hide();
				$(
					".main-nav #navitem-menu .subnav, .main-nav #navitem-about .subnav"
				).mouseleave(function () {
					$(
						".main-nav #navitem-menu .subnav, .main-nav #navitem-about .subnav"
					).fadeOut();
					$(".main-nav #navitem-stories .subnav").fadeIn();
				});
				$(".main-nav #navitem-stories .subnav").on("mouseenter", function () {
					$(".stories-feature").show();
				});
				$(window).scroll(function () {
					if (
						$(this).scrollTop() >
						storiesHeight + $("#scPageExtendersForm").height()
					) {
						$(".header-frame").addClass("lock");
						$(".nav-toggle").addClass("active");
						$(".navitem-hoverable a").addClass("off-screen");
						$(".main-nav #navitem-about .subnav").slideUp("slow");
						$(".main-nav #navitem-menu .subnav").slideUp("slow");
						$(".main-nav #navitem-stories .subnav").slideUp("slow");
					} else {
						$(".header-frame").removeClass("lock");
						$(".nav-toggle").removeClass("active");
						$(".navitem-hoverable a").removeClass("off-screen");
						$(".main-nav #navitem-stories .subnav").slideDown("slow");
						$(".stories-feature").hide();
					}
				});
			} else {
				$(window).scroll(function () {
					if ($(this).scrollTop() > 100) {
						$(".header-frame").addClass("lock");
						$(".nav-toggle").addClass("active");
						$(".navitem-hoverable a").addClass("off-screen");
					} else {
						$(".header-frame").removeClass("lock");
						$(".nav-toggle").removeClass("active");
						$(".navitem-hoverable a").removeClass("off-screen");
					}
				});
			}
			$(".truett-memorial").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(".truett-img .lrg-img").prop("src") + ")"
				);
				$(".truett-img").hide();
			});
			$(".stories-feature").mouseleave(function (e) {
				var $this = $(this);
				var bottom = $this.offset().top + $this.height();
				if (bottom < e.pageY) {
					$(this).fadeOut();
				}
			});
			// If subnav is visible and user leaves the main nav area, hide the subnav if not one of the special pages
			$(".main-nav").mouseleave(function () {
				if (!$(".story-landing, .about").length) {
					if ($(".main-nav .subnav:visible")) {
						$(".main-nav .subnav").delay(200).fadeOut();
					}
				}
			});
			// main-menu and sub-menu access using by keypad
			$(".main-nav ul>li>a").focus(function () {
				$(this).next("div").css("display", "block");
				$(this).parent().siblings().children("div").css("display", "none");
				if ($(this).parent().hasClass("cfa-one")) {
					$(this).parent().siblings().children("div").css("display", "none");
				}
			});
			// Turn Feature stories within the nav into clickable boxes
			if (!expEditor) {
				$(".stories-feature>div")
					.not(":first")
					.click(function () {
						window.location.href = $(this).find("a").attr("href");
						return false;
					});
			}
			// Section Nav actions
			if ($(".home")[0]) {
				$(".navitem-hoverable a").on("mouseenter", function () {
					if ($(this).siblings(".subnav").length > 0) {
						$(".header-frame").addClass("hovered");
						if (!$(this).siblings(".subnav").is(":visible")) {
							$("body").addClass("snav-visible");
							$(".main-nav .subnav").fadeOut("fast");
							if ($(".header-frame").hasClass("locked")) {
								$(this).siblings(".subnav").delay(150).fadeIn("fast");
							} else {
								$(this).siblings(".subnav").delay(150).fadeIn("fast");
							}
						}
					}
				});
			} else {
				$(".main-nav>ul>li>a").on("mouseenter", function () {
					if ($(this).siblings(".subnav").length > 0) {
						$(".header-frame").addClass("hovered");
						if (!$(this).siblings(".subnav").is(":visible")) {
							$("body").addClass("snav-visible");
							$(".main-nav .subnav").fadeOut("fast");
							if ($(".header-frame").hasClass("locked")) {
								$(this).siblings(".subnav").delay(150).fadeIn("fast");
							} else {
								$(this).siblings(".subnav").delay(150).fadeIn("fast");
							}
						}
					}
				});
			}
			$(".header-frame").on("mouseleave", function () {
				$(this).removeClass("hovered");
			});
			$(".home-menu a").click(function () {
				$(".home-menu a").removeClass("current");
				$(this).addClass("current");
			});
			// Hamburger menu on desktop functionality
			$(".nav-toggle a").on("mouseenter", function (e) {
				$(this).parent().removeClass("active");
				$(".navitem-hoverable a").removeClass("off-screen");
				e.preventDefault();
			});
			// Show Location Search
			$(".change, .menu-hero-edit").click(function (e) {
				$(".location-tools").addClass("location-tools-open");
				$(".location-search-frame").show();
				$("#location-search").focus().select();
				$(".current-location, .main-nav").hide();
				e.preventDefault();
			});
			$(".location-search-frame .icon-close").click(function (e) {
				$(".location-tools").removeClass("location-tools-open");
				$(".location-search-frame").hide();
				$(".current-location, .main-nav").show();
				e.preventDefault();
			});
			$(window).on("scroll", function () {
				if ($(".story-top")[0]) {
					var storyTop = $(".story-top");
					var topTag = storyTop.offset().top;
					$(window).scroll(function () {
						var scrollTop = $(window).scrollTop();
						var toet = topTag - scrollTop;
					});
				}
			});
			// On About Pages, apply background when user hovers over the main nav items
			$(".about .header-frame").mouseenter(function () {
				$(this).addClass("hover");
			});
			$(".about .main-nav").mouseleave(function () {
				setTimeout(function () {
					$(".about .header-frame").removeClass("hover");
				}, 800);
			});
			// Search
			$(".site-search .icon-search-off").click(function (e) {
				if (!$("#navitem-search").hasClass("active")) {
					$(".search, .main-nav").addClass("active");
					$("#navitem-search input:text").focus();
					e.preventDefault();
				}
			});
			$(".search .icon-close").click(function () {
				$(".search, .main-nav").removeClass("active");
				$("#keyword").attr("aria-expanded", "false");
			});
			// Apply background image to hero banners
			if (!$("body").hasClass("sc-editor")) {
				$(".top-hero").each(function () {
					$(this).css(
						"background-image",
						"url(" + $(".lrg-img").prop("src") + ")"
					);
					$(".hero-img img, .bio-img img").hide();
				});
				// Specifically for story carousels
				$(".lead-story, .product-banner").each(function () {
					$(this).css(
						"background-image",
						"url(" + $(this).find(".lrg-img").prop("src") + ")"
					);
					$(this).css("background-size", "cover");
					$(".slide-bkgd").hide();
				});
			}

			$(".lrg-app-banner").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find(".lrg-img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("background-position-y", "initial");
				$(".lrg-app-banner .app-banner-image").hide();
			});
			// 404
			$.fn.rotate = function (degrees) {
				$(this).css({
					"-webkit-transform": "rotate(" + degrees + "deg)",
					"-moz-transform": "rotate(" + degrees + "deg)",
					"-ms-transform": "rotate(" + degrees + "deg)",
					transform: "rotate(" + degrees + "deg)"
				});
				return $(this);
			};

			//Nutrients Page, Click Event Desktop View
			if ($(".nutrition-allergens-table").length > 0) {
				//show breakfast class on open
				$('a[href="#breakfast-nutrition"]').addClass("selected");
				$(".home-menu li a").click(function (e) {
					e.preventDefault();

					$(".home-menu").addClass("lock");

					var target = $(this).attr("href"); // Set the target as variable
					var offset = -200;

					jumpToNutrientsLink(target, offset);
					return false;
				});
				//extend y-axis to allow table to overflow
				$(".frame main").addClass("overflow-y-enable");

				// z-index fix for tooltip overlap
				var $allergensTables = $(".allergens-table");
				var $startingIndex = $allergensTables.length + 1;

				$allergensTables.each(function (i) {
					$(this).css("z-index", $startingIndex - i);
				});

				$(window).scroll(function () {
					stickyNutriAllergNavBar();
				});
			}

			$(function () {
				if ($("._404").length == 0) {
					//do nothing if this is not a 404 page.
					return;
				}
				var speed = 2000;
				var delayOnLoad = 2000;
				var width = $(window).width();
				$(".frame").css("overflow", "hidden");
				$(
					"._404 .no-results-top, ._404 .no-results-top .wrapper, ._404 .no-results-top .wrapper .content"
				).css("position", "initial");
				var note = $(".sticky-note");
				var hoof = $(".hoof");
				var frame = $(".frame");
				var noteWidth = note.width();
				var noteHeight = note.height();
				var hoofWidth = hoof.width();
				var hoofHeight = hoof.height();
				var frameWidth = frame.width();
				var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
				if (isIE11 === true) {
					var noteTopFinish = -75;
				} else {
					var noteTopFinish = 90;
				}
				var hoofTopFinish = -150;
				var noteRightFinish;
				var hoofRightFinish;
				if (isIE11 === true) {
					var reInitRightFinish = function (w) {
						noteRightFinish = w / 2 - noteWidth / 2 - noteWidth + 350;
						hoofRightFinish = w / 2 - hoofWidth / 2 - noteWidth + 50;
					};
				} else {
					var reInitRightFinish = function (w) {
						noteRightFinish = w / 2 - noteWidth / 2;
						hoofRightFinish = w / 2 - hoofWidth / 2 - noteWidth + 50;
					};
				}
				reInitRightFinish(width);
				var startDiff = noteTopFinish + noteHeight;
				if (isIE11 === true) {
					var noteTopStart = noteTopFinish - startDiff - 70;
				} else {
					var noteTopStart = noteTopFinish - startDiff;
				}
				var noteRightStart = noteRightFinish - startDiff;
				var hoofTopStart = hoofTopFinish - startDiff;
				var hoofRightStart = hoofRightFinish - startDiff;
				hoof.rotate(-15);
				note.css("left", "auto");
				note.css("top", noteTopStart + "px");
				note.css("right", noteRightStart + "px");
				note.css("display", "block");
				hoof.css("top", hoofTopStart + "px");
				hoof.css("right", hoofRightStart + "px");
				hoof.css("display", "block");
				var top = 20 + noteHeight;
				var right = noteWidth;
				var updateIfNeeded = function () {
					setInterval(function () {
						var currentWidth = $(window).width();
						if (currentWidth != width) {
							width = currentWidth;
							reInitRightFinish(width);
							note.css("right", noteRightFinish + "px");
							hoof.css("right", hoofRightFinish + "px");
						}
					}, 500);
				};
				var animate = function () {
					note.animate(
						{
							top: "+=" + (noteTopFinish - noteTopStart),
							right: "+=" + (noteRightFinish - noteRightStart)
						},
						speed,
						function () {
							var pItems = $(".content p");
							$(pItems.get(0)).css("visibility", "hidden");
							$(pItems.get(1)).css("visibility", "hidden");

							updateIfNeeded();
						}
					);
					hoof.animate(
						{
							top: "+=" + (hoofTopFinish - hoofTopStart),
							right: "+=" + (hoofRightFinish - hoofRightStart)
						},
						speed,
						function () {
							hoof.animate(
								{
									top: "-=" + (hoofTopFinish - hoofTopStart),
									right: "-=" + (hoofRightFinish - hoofRightStart)
								},
								2000,
								function () {}
							);
						}
					);
				};
				setTimeout(function () {
					animate();
				}, delayOnLoad);
			});
			// Equalize Heights
			$(".b-logo").matchHeight();
			$(".award-blurb").matchHeight();
			// History component
			$(".history-toggle li:first").addClass("current");
			$(".history-module").not(":first").hide();
			$(".history-toggle a").mouseenter(function (e) {
				if (!$(this).parent("li").hasClass("current")) {
					var historyID = $(this).attr("href").substring(1);
					$(".history-toggle li").removeClass("current");
					$(this).parent("li").addClass("current");
					$(".history-module")
						.not("#" + historyID)
						.hide();
					$(".history-module#" + historyID).show();
				}
				e.preventDefault();
			});
			$(".history-toggle a").click(function (e) {
				e.preventDefault();
			});
			//  FAQ
			if ($(".contact-support")[0]) {
				if (window.location.hash != "") {
					var selectFAQ = window.location.hash.substring(1);
					if ($("dl.faq#" + selectFAQ)[0]) {
						$('a[href$="' + selectFAQ + '"]')
							.parent("li")
							.addClass("current");
						$("dl.faq")
							.not("#" + selectFAQ)
							.hide();
						$("html, body").animate(
							{
								scrollTop: $("#faq").offset().top - +75
							},
							500
						);
					} else {
						$("dl.faq").not(":first").hide();
						$(".faq-toggle li:first")
							.addClass("current")
							.attr("aria-pressed", "true");
					}
				} else {
					$("dl.faq").not(":first").hide();
					$(".faq-toggle li:first").addClass("current");
					$(".faq-toggle li:first").attr("aria-pressed", "true");
				}
				$(".faq-toggle a").click(function (e) {
					$("html, body").animate(
						{
							scrollTop: $("#faq").offset().top - +75
						},
						500
					);
					if (!$(this).parent("li").hasClass("current")) {
						var faqID = $(this).attr("href").substring(1);
						$(".faq-toggle li").removeClass("current");
						$(".faq-toggle li").attr("aria-pressed", "false");
						$(this).parent("li").addClass("current");
						$(this).parent("li").attr("aria-pressed", "true");
						$("dl.faq")
							.not("#" + faqID)
							.hide();
						$("dl.faq#" + faqID).show();
					}
					e.preventDefault();
				});
			}
			// Editorial - Homepage
			//if (!$('body').hasClass('sc-editor')) {
			// Apply classes to each story in the homepage editorial section
			// Adding a extra class to stories div to show hide it.
			var i = 0;
			$("#stories").find(".stories").addClass("hide-desktop");
			$("#stories .stories>div").each(function () {
				i++;
				var storyClass = "story" + i;
				$(this).attr("class", storyClass);
				$(this).val(i);
			});
			// Take the image within the story and apply it as a background image
			$("#stories .stories>div").each(function () {
				i++;
				var newID = "editorial" + i;
				$(".three .stories>div img").hide();
				$(this).attr("id", newID);
				$(this).val(i);
				if (
					navigator.userAgent.indexOf("Safari") != -1 &&
					navigator.userAgent.indexOf("Chrome") == -1
				) {
					$("#editorial" + i).css(
						"background-image",
						"url(" +
							$("#editorial" + i)
								.find("img")
								.prop("srcset") +
							")"
					);
				} else if (
					!!navigator.userAgent.match(/Trident.*rv\:11\./) ||
					navigator.userAgent.match(/MSIE 10/)
				) {
					$("#editorial" + i).css(
						"background-image",
						"url(" +
							$("#editorial" + i)
								.find("img")
								.prop("src") +
							")"
					);
				} else {
					$("#editorial" + i).css(
						"background-image",
						"url(" +
							$("#editorial" + i)
								.find("img")
								.prop("src") +
							")"
					);
				}
				$("#editorial" + i).css("background-position", "50% 50%");
			});
			// Editorial - insert flexbox around stories 2 and 3 if three story layout
			if (!$(".sc-editor")[0]) {
				$("#stories.three .story2, #stories.three .story3").wrapAll(
					'<div class="flex"></div>'
				);
			}
			//}
			// Apply Story Feed Background Image
			$(".related .story")
				.not(".full, .top-shared")
				.each(function () {
					$(this)
						.children("a")
						.first()
						.css(
							"background-image",
							"url(" + $(this).find("img").prop("src") + ")"
						);
					$(this).children("a").first().css("background-size", "cover");
					$(this).children("a").first().css("background-position", "center");
					$(this).find("img").hide();
				});

			// About Pages apply background - Desktop
			$(".truett-intro").each(function () {
				var theImage = $(this).find("picture .lrg-img");
				$(this).css("background-image", "url(" + $(theImage).prop("src") + ")");
				$(this).find("picture").hide();
			});

			// Apply Story Nav img as background
			$(".story-img").each(function () {
				$(this).css(
					"background-image",
					"url(" + $(this).find("img").prop("src") + ")"
				);
				$(this).css("background-size", "cover");
				$(this).css("background-position", "center");
				$(this).find("img").hide();
			});
			// Blue social bar on stories
			if ($(".story-article .cfacom_cw").length === 1) {
				var element = $(".story-top");
				var distance = element.offset().top,
					$window = $(window);
				$window.scroll(function () {
					if ($window.scrollTop() >= distance) {
						$(".story-top").addClass("locked");
						$(".story-article .content").css("padding-top", "184px");
					} else {
						$(".story-top").removeClass("locked");
						$(".story-article .content").css("padding-top", "0");
						$(".social").removeClass("slideout");
						$(".social-icons.default").show();
						$(".social-icons.alt").hide();
					}
				});
			}
			// Sauces overlay
			$(".wire-logo").mouseenter(function () {
				$(".hover-content").fadeIn(500);
				$(".wire-title").fadeIn(500);
				$(".wire-logo").addClass("wire-logo-shadow");
			});
			$(".wire-logo").mouseleave(function () {
				$(".hover-content").fadeOut(500);
				$(".wire-title").fadeOut(500);
				$(".wire-logo").removeClass("wire-logo-shadow");
			});
		},
		onLeave: function () {
			if (!expEditor) {
				location.reload();
			}
		}
	});

	// Make whole slides clickable, not just the link
	if (!expEditor) {
		$(".lead-story").click(function () {
			var theLink = $(this).find("a").attr("href");
			window.location.href = theLink;
			window.dataLayer.push({
				event: "storyClick",
				storyHref: theLink
			});
		});
		$(".related .story").click(function () {
			var theLink = $(this).find("h3 a").attr("href");
			if (theLink !== undefined) {
				window.location.href = theLink;
			}
		});
		$(
			".related .story a, div.story > a, .story .slide-content a, .lead-story .slide-content a"
		).click(function (e) {
			window.location.href = $(this).attr("href");
			//e.preventDefault();
		});
	}

	if ($("h1").is(":visible") && $("h1").text() === "") {
		var title;
		var incorrectHeaderTag;
		var correctHeaderTag;
		title = $.trim($(".intro > h2").text());
		incorrectHeaderTag = $(":header").filter(function () {
			return $(this).text() === title;
		});
		if (title.length > 0) {
			correctHeaderTag = $("<h1 />", { text: title });
			incorrectHeaderTag.replaceWith(correctHeaderTag);
		}
	}

	storiesToggle();
	// Homepage Editorial, when clicked, slide up / down editorial pane, change text on toggle
	function storiesToggle() {
		if (!$("body").hasClass("sc-editor")) {
			$(".stories-toggle").on("click", function () {
				if ($(this).parent("#stories").hasClass("teaser")) {
					$("#stories").removeClass("teaser");
					$("#stories").addClass("shown");
					$(".stories").toggleClass("hide-desktop");
					$("#stories .toggle-text").attr("aria-expanded", "true");
					$(".frame").addClass("content-hide");
					$(".header-frame > header").addClass("content-hide");
					$(".stories > div").find("span.title").attr("tabindex", "0");
				} else {
					$("#stories").addClass("teaser");
					$("#stories").removeClass("shown");
					$(".stories").toggleClass("hide-desktop");
					$("#stories .toggle-text").attr("aria-expanded", "false");
					$(".frame").removeClass("content-hide");
					$(".header-frame > header").removeClass("content-hide");
					$(".stories > div").find("span.title").removeAttr("tabindex");
					$(".stories > div")
						.find(".hover-focus")
						.addClass("hover")
						.removeClass("hover-focus");
					$(".stories > div")
						.find(".initial-focus")
						.addClass("initial")
						.removeClass("initial-focus");
				}
				$("body").toggleClass("nav-shown");
			});
		}

		$("#stories").keydown(function (e) {
			if (!$("body").hasClass("sc-editor")) {
				if (e.keyCode == 27 || e.which == 27) {
					if ($(".stories-toggle").parent("#stories").hasClass("shown")) {
						$("#stories").addClass("teaser").removeClass("shown");
						$(".stories").toggleClass("hide-desktop");
						$(".frame").removeClass("content-hide");
						$(".header-frame > header").removeClass("content-hide");
						$(".stories > div").find("span").removeAttr("tabindex");
						$(".stories > div")
							.find(".hover-focus")
							.addClass("hover")
							.removeClass("hover-focus");
						$(".stories > div")
							.find(".initial-focus")
							.addClass("initial")
							.removeClass("initial-focus");
						$("body").toggleClass("nav-shown");
					}
				}
				if ((e.keyCode == 13 || e.which == 13) && $(".closed").is(":focus")) {
					if ($(".stories-toggle").parent("#stories").hasClass("teaser")) {
						$(".stories > div").find("span").attr("tabindex", "0");
						$("#stories").addClass("shown").removeClass("teaser");
						$(".stories").toggleClass("hide-desktop");
						$(".frame").addClass("content-hide");
						$(".header-frame > header").addClass("content-hide");
						setTimeout(function () {
							$(".opened").focus();
						}, 2000);
					}
					$("body").toggleClass("nav-shown");
				}
			}
		});
		// make content in stories visible on focus for ada
		$("#stories .btn").on("focus", function (e) {
			var $this = $(this);
			var $hoverEl = $this.parents(".alignment");
			$(".visible-story").removeClass("visible-story");
			$hoverEl.addClass("visible-story");
		});
		$(document).keydown(function (e) {
			if (e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
				if (!$("body").hasClass("sc-editor")) {
					if ($(".stories-toggle").parent("#stories").hasClass("shown")) {
						if ($(".stories > div").find(".hover-focus")) {
							$(".stories > div")
								.find(".hover-focus")
								.find(".lost-focus")
								.focus();
						}
					}
				}
			}
		});
		$(".stories > div")
			.find("span")
			.focus(function (event) {
				if (!$("body").hasClass("sc-editor")) {
					if ($(".stories-toggle").parent("#stories").hasClass("shown")) {
						$(this).offsetParent().parent().addClass("story-focus");
						$(this)
							.offsetParent()
							.find(".hover")
							.addClass("hover-focus")
							.removeClass("hover");
						$(this)
							.offsetParent()
							.find(".initial")
							.addClass("initial-focus")
							.removeClass("initial");
						event.stopPropagation();
					}
				}
			});

		$(".lost-focus").focusout(function (event) {
			if (!$("body").hasClass("sc-editor")) {
				if ($(".stories-toggle").parent("#stories").hasClass("shown")) {
					$(this).offsetParent().parent().removeClass("story-focus");
					$(this)
						.offsetParent()
						.find(".hover-focus")
						.addClass("hover")
						.removeClass("hover-focus");
					$(this)
						.offsetParent()
						.find(".initial-focus")
						.addClass("initial")
						.removeClass("initial-focus");
					event.stopPropagation();
				}
			}
		});
	}

	// First 100
	$(".eligible .toggle").click(function () {
		$(this).parent().toggleClass("active");
	});

	// Pricing tool tip for focus
	$(".product-focus .icon-info").focus(function () {
		$(this)
			.children(".tooltip")
			.addClass("tooltip-focus")
			.removeClass("tooltip");
	});

	$(".product-focus .icon-info").focusout(function () {
		$(this)
			.children(".tooltip-focus")
			.addClass("tooltip")
			.removeClass("tooltip-focus");
	});
	// Show Sunday Video
	if (!$("body").hasClass("sc-editor")) {
		if ($("div").hasClass("sunday-video")) {
			$(".sunday-video").fadeIn("slow");
			$("body").addClass("nav-shown");
		}
	}

	// Replay Sunday Video and hide overlay {
	$(".replay").on("click", function (e) {
		$("#player")[0].src += "&autoplay=1";
		$(".post-video").fadeOut();
		e.preventDefault();
	});

	$(".home-menu>.subnav>ul>li").click(function () {
		$(".home-menu>.subnav>ul>li").each(function () {
			$(this).find(".visually-hidden").remove();
		});
		var span = $("<span />").addClass("visually-hidden").html("Selected");
		$(this).find("a").append(span);
	});
	// Show / Hide informative banner
	$(".informative-banner a.overlay").click(function (e) {
		console.log("clicked");
		e.preventDefault();
		$(".informative-notice").fadeIn();
		$("body").addClass("nav-shown");
	});
	// Show / Hide emergency alert
	$(".emergency-alert a.overlay").click(function (e) {
		$(".emergency-notice").fadeIn();
		$("body").addClass("nav-shown");
		e.preventDefault();
	});
	$(
		".emergency-notice .close, .emergency-notice .btn, .informative-notice .close, .informative-notice .btn,  .sunday-video .close, .close-overlay"
	).click(function (e) {
		var link = $.trim($(this).attr("href"));
		if (link === "#" || link.length == 0) {
			e.preventDefault();
		}

		$(".emergency-notice, .informative-notice, .sunday-video").fadeOut("fast");
		$("body").removeClass("nav-shown");
		var windowHeight = $(window).height();
		var theHeight = windowHeight - 66;
		$(".stories").css("height", theHeight);
		var storiesHeight = $(".stories").height();
		var storiesScroll = storiesHeight - 70;
		$(".vid-wrapper").remove();
	});

	// Scroll to menu
	$(".product-banner .scroller").click(function () {
		$(".product-banner").addClass("closed");
	});
	var top;
	$(".scroller.menu").click(function () {
		$("html, body").animate(
			{
				scrollTop: $("#menu").offset().top - +75
			},
			1000
		);
	});

	// Nutrition Overlay Toggle
	$(".show-overlay").click(function () {
		$(".product-focus").attr("aria-hidden", "true");
		$(".nutrition-details").addClass("shown");
		$("body").addClass("overlay-shown");
		$(".footer").addClass("content-hide");
		$("#product-description").addClass("content-hide");
		$(".nutrition-details").find("#ingredient").focus();
	});
	$(".hide-overlay, .nutrition-details .icon-close").click(function () {
		$(".show-overlay").focus();
		$(".product-focus").attr("aria-hidden", "false");
		$(".nutrition-details").removeClass("shown");
		$("body").removeClass("overlay-shown");
		$(".footer").removeClass("content-hide");
		$("#product-description").removeClass("content-hide");
	});
	//nutrition overlay closing by escape
	$(document).keydown(function (e) {
		if ($(".nutrition-details").hasClass("shown")) {
			if (e.keyCode == 27 || e.which == 27) {
				$(".nutrition-details").removeClass("shown");
				$("body").removeClass("overlay-shown");
				$(".product-focus").find(".show-overlay.desktop-only").focus();
				$(".footer").removeClass("content-hide");
				$("#product-description").removeClass("content-hide");
			}
		}
		if (
			(e.keyCode == 13 || e.which == 13) &&
			$(".show-overlay.desktop-only").is(":focus")
		) {
			$(".nutrition-details").addClass("shown");
			$(".nutrition-details").find("#ingredient").focus();
			$(".footer").addClass("content-hide");
			$("#product-description").addClass("content-hide");
		}
	});

	// Array to Store Items added to the Nutrition Calculator Bucket
	var bucketArray = [];

	// Store Item Count for Nutrition Calculator
	var bucketLength = bucketArray.length;
	$(".menu-calculator").on("click", ".add", function () {
		var basketItem = $(this).closest("tr.Meal").clone();
		basketItem.children().find(".add").removeClass("add").addClass("subtract");
		basketItem.find(".checkbox").each(function () {
			var time = Date.now();
			$(this)
				.find("input")
				.attr("id", $(this).find("input").attr("id") + time);
			$(this)
				.find("label")
				.attr("for", $(this).find("label").attr("for") + time);
		});
		basketItem.appendTo($(".nutrition-calculator").children("tbody"));
		// Update the Count for the Nutrition Calculator
		bucketLength += 1;
		$(".bucket-count").text(bucketLength);
		// Store Items added to the Nutrition Calculator in an Array
		bucketArray.push($(this).next().data());
		$(".BucketHead").show();
		$(".BucketHeadToAdd").hide();
		updateNutritionCalcTotals();
		console.log(bucketArray);
		console.log(bucketArray.length);
	});

	// Remove Item from Nutrition Calculator
	$(".menu-calculator").on("click", ".subtract", function () {
		$(this).removeClass("subtract").addClass("add");
		// Update the Count for the Nutrition Calculator
		bucketLength -= 1;
		$(".bucket-count").text(bucketLength);
		if (bucketLength == 0) {
			$(".BucketHead").hide();
			$(".BucketHeadToAdd").show();
		}
		updateNutritionCalcTotals();
	});

	$(".nutrition-calculator").on("click", ".subtract", function () {
		$(this).closest("tr.Meal").remove();
		bucketLength -= 1;
		if (bucketLength == 0) {
			$(".BucketHead").hide();
			$(".BucketHeadToAdd").show();
		}
		$(".bucket-count").text(bucketLength);
		bucketArray.splice(FindItemIndexInBucket($(this).next().data()), 1);

		updateNutritionCalcTotals();
	});
	$(".AllergenFilter, input[name=AllergySelection]").on("click", function () {
		if ($("input[name=AllergySelection]:checked")[0].id == "exclude") {
			$(".Meal").show();
			var selectedItems = $(".AllergenFilter:checked");
			$.each(selectedItems, HideItems);
		} else {
			$(".Meal").hide();
			var selectedItems = $(".AllergenFilter:checked");
			$.each(selectedItems, ShowItems);
		}
	});
	function FindItemIndexInBucket(item) {
		for (var i = 0, len = bucketArray.length; i < len; i++) {
			if (bucketArray[i].id === item.id) return i; // Return as soon as the object is found
		}
		return -1; // The object was not found
	}
	function HideItems(item) {
		$("." + $(this)[0].id).hide();
	}
	function ShowItems(item) {
		$("." + $(this)[0].id).show();
	}

	// Add Qty Dropdowns for each Bucket List Item in Nutrition Calculator
	// Expand Nutrition Calculator
	$(".js-calcContracted").on("click", function () {
		//$(location).attr('href', 'menu-calculator-expanded.html');
	});

	// Contract Nutrition Calculator
	$(".js-calcExpanded").on("click", function () {
		//$(location).attr('href', 'menu-calculator.html');
	});

	// Menu Calculator - Accessibility
	// Make each cell Tabable
	$(".menu-calculator td").attr("tabindex", 0);

	// Add an Aria Label combining the data label and cell contents
	// $('.menu-calculator td').each(function(){
	// 	var dataLabel = $(this).data('label');
	// 	var dataValue = $(this).text();
	// 	var ariaValue = dataLabel+'='+dataValue;
	// 	$(this).attr('aria-label', ariaValue);
	// });

	// Menu Accordion in tables
	$(".menu-accordion").each(function () {
		var tbody = $(this).find("tbody");
		tbody.hide();
	});

	$(".nutriAllerg-menu-accordion").each(function () {
		var tbody = $(this).find("tbody");
		tbody.hide();
	});
	$(".menu-calculator").on("click", ".menu-accordion-trigger", function () {
		$(this).toggleClass("rotate");
		$(this).closest("table").find("tbody").fadeToggle();
	});

	$(".nutrition-allergens-table").on(
		"click",
		".menu-accordion-trigger",
		function () {
			$(this).toggleClass("rotate");
			$(this).closest("table").find("tbody").fadeToggle();
		}
	);
	//For nutrients/allergens table keypress = click
	$(".menu-accordion-trigger").keypress(function (e) {
		$(this).trigger("click");
	});
	$(".menu-calculator").on("click", ".menu-accordion-title", function () {
		$(this).siblings(".menu-accordion-trigger").toggleClass("rotate");
		$(this).closest("table").find("tbody").fadeToggle();
	});

	$(".nutrition-allergens-table").on(
		"click",
		".menu-accordion-title",
		function () {
			$(this).siblings(".menu-accordion-trigger").toggleClass("rotate");
			$(this).closest("table").find("tbody").fadeToggle();
		}
	);

	// Mobile for Menu Calculator
	$(".js-nutritionButton").on("click", function () {
		$(this).removeClass("white");
		$(this).next().addClass("white");
		$(this)
			.parent()
			.parent()
			.parent()
			.next()
			.find(".custom-nutrition")
			.css("display", "block");
		// Below is to show total nutrition for mobile
		// $(this).parent().parent().parent().parent().prev().find('.item-nutrition').css('display','block');
		$(this).parent().parent().parent().next().find(".custom-item").slideUp();
	});
	$(".js-customizeButton").on("click", function () {
		$(this).removeClass("white");
		$(this).prev().addClass("white");
		$(this).parent().parent().parent().next().find(".custom-item").slideDown();
		$(this)
			.parent()
			.parent()
			.parent()
			.next()
			.find(".custom-nutrition")
			.slideUp();
	});

	//nutrition calc change variant select
	$(".menu-calculator").on("change", ".mc-variant", function () {
		$(this).parent().parent().siblings(".custom-nutrition").hide();
		$(this)
			.parent()
			.parent()
			.siblings('.custom-nutrition[data-id="' + $(this).val() + '"]')
			.show();
		updateItemNutritionTotals($(this).closest("table"));
	});
	$(".menu-calculator").on(
		"change",
		".mc-group3 :checkbox, .mc-group3 select",
		function () {
			updateItemNutritionTotals($(this).closest("table"));
		}
	);
	$(".menu-calculator").on("change", ".mc-group2 :checkbox", function () {
		updateItemNutritionTotals($(this).closest("table"));
	});

	function updateItemNutritionTotals(item) {
		var nutrition = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		$(item)
			.find("tbody tr.selected")
			.each(function () {
				if ($(this).hasClass("mc-group1")) {
					$(this)
						.find(
							'.custom-nutrition[data-id="' +
								$(this).find(".mc-variant").val() +
								'"]'
						)
						.each(function (index) {
							var nutritionVal = parseInt($(this).text()) || 0;
							nutrition[index] += nutritionVal;
						});
				} else if ($(this).hasClass("mc-group3")) {
					if ($(this).find(":checkbox").is(":checked")) {
						var numSelected =
							parseInt($(this).find("select option:selected").val()) || 1;
						$(this)
							.find(".custom-nutrition")
							.each(function (index) {
								var nutritionVal = parseInt($(this).text()) || 0;
								nutrition[index] += nutritionVal * numSelected;
							});
					}
				} else if ($(this).hasClass("mc-group2")) {
					if ($(this).find(":checkbox").is(":checked")) {
						$(this)
							.find(".custom-nutrition")
							.each(function (index) {
								var nutritionVal = parseInt($(this).text()) || 0;
								nutrition[index] += nutritionVal;
							});
					}
				}
			});

		$(item)
			.find("thead td")
			.slice(1)
			.each(function (index) {
				$(this).text(nutrition[index]);
			});
		if ($(item).parents(".nutrition-calculator").length > 0) {
			updateNutritionCalcTotals();
		}
	}

	function updateNutritionCalcTotals() {
		var nutrition = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		$(".nutrition-calculator .menu-accordion thead tr").each(function () {
			$(this)
				.find("td")
				.slice(1)
				.each(function (index) {
					var nutritionVal = parseInt($(this).text()) || 0;
					nutrition[index] += nutritionVal;
				});
		});

		$(".nutrition-calculator .totals-row td")
			.slice(1)
			.each(function (index) {
				$(this).text(nutrition[index]);
			});
	}

	//end nutrition calc

	// Scroll to menu
	$(".scroller a").click(function (e) {
		$("html, body").animate(
			{
				scrollTop: $("body").offset().top - 100
			},
			500
		);
		e.preventDefault();
	});

	// Footer
	$(".footer .has-sub>a").click(function (e) {
		if ($(this).siblings().is(":visible")) {
			$(this).siblings().slideUp();
			$(this).removeClass("active");
		} else {
			$(this).siblings().slideDown();
			$(this).addClass("active");
		}
		e.preventDefault();
	});

	// Lazyload
	$(".lazy").lazyload({
		threshold: 100,
		effect: "fadeIn"
	});

	// Story Landing Carousel
	if (!$("body").hasClass("sc-editor")) {
		$(".story-carousel").slick({
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 4000
		});
	}

	// Fullsize image story landing
	var i = 0;
	$(".story.full").each(function () {
		i++;
		var newID = "fullImg" + i;
		$(".story.full img").hide();
		$(this).attr("id", newID);
		$(this).val(i);
		$("#fullImg" + i).css(
			"background-image",
			"url(" +
				$("#fullImg" + i)
					.find("img")
					.prop("src") +
				")"
		);
		$("#fullImg" + i).css("background-position", "50% 50%");
	});

	// Disable select menu if has parent disabled class
	$(".disabled select").prop("disabled", "disabled");

	// Next section and related story blocks - find link in area and make whole area clickable
	if (!expEditor) {
		$(".next-section").click(function () {
			window.location.href = $(this).find("a").attr("href");
			return false;
		});
	}

	// Select Size Menu
	$(".option-select .selectlist").click(function () {
		$(this).children(".options").slideToggle();
		$(this).children(".icon-arrow-down").toggleClass("active");
	});
	// story image carousel captioned image handling.
	if ($(".story-image-carousel")[0]) {
		$(".captionedImage").css("display", "none");
	} else {
		$(".captionedImage").css("display", "block");
	}

	// Simple Clientside form validation
	$("form.userform").validate({
		highlight: function (element) {
			$(element).closest("p").addClass("error");
		},
		unhighlight: function (element) {
			$(element).closest("p").removeClass("error");
		}
	});

	// Social Icons Switch
	$(".icon-ellipses-s1").on("click", function (e) {
		$(this).closest(".social-icons").addClass("active");
		e.preventDefault();
	});
	$(".icon-close-s2").on("click", function (e) {
		$(this).closest(".social-icons").removeClass("active");
		e.preventDefault();
	});

	// Modal lightbox
	$("a[href='#oloorderlbid']").addClass("lb-modal");
	$(".lb-modal").magnificPopup({
		type: "inline",
		preloader: false,
		modal: false,
		closeOnBgClick: true,
		fixedContentPos: false
	});
	$(".video-modal").not(".related .story .yt-video").magnificPopup({
		disableOn: 700,
		type: "iframe",
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	// Datepicker
	var thisYear = new Date().getFullYear();
	var thisMonth = new Date().getMonth();
	if (thisMonth && thisMonth == 11) {
		thisYear = thisYear + 1;
	}
	$(".datepicker").datepicker({
		changeMonth: true,
		changeYear: true,
		yearRange: "1930:" + thisYear
	});

	// Sauce animation
	$(".product.sauce, .companion.sauce").each(function () {
		var thisSauce = $(this).find("img").attr("src");
		$(this).find("img").hide();
		$(this)
			.children(".the-sauce")
			.css("background-image", "url(" + thisSauce + ")");
	});
	$(".the-sauce").mouseenter(function () {
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6500px top");
				next();
			});
	});
	$(".the-sauce").mouseleave(function () {
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-6000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-5000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-4000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-3000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-2000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-1000px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-750px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-500px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "-250px top");
				next();
			});
		$(this)
			.delay(25)
			.queue(function (next) {
				$(this).css("background-position", "left top");
				next();
			});
	});

	$("body").on("click", ".closest-pickup-close", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "closeOverlay_PUA"
		});
	});
	$("body").on("click", ".closest-pickup-cont", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "continue_PUA"
		});
	});

	$.ui.autocomplete.prototype._renderItem = function (ul, item) {
		function replaceAt(source, index, char) {
			return (
				source.substr(0, index) + "<span class='highlight'>" + char + "</span>"
			);
		}

		this.term = this.term.toLowerCase();
		var resultStr = item.label.toLowerCase();
		var t = "";
		while (resultStr.indexOf(this.term) != -1) {
			var index = resultStr.indexOf(this.term);
			t =
				t +
				replaceAt(
					item.label,
					index,
					item.label.slice(index, index + this.term.length)
				);
			resultStr = resultStr.substr(index + this.term.length);
			item.label = item.label.substr(index + this.term.length);
		}
		return $("<li></li>")
			.data("item.autocomplete", item)
			.append("<a>" + t + item.label + "</a>")
			.appendTo(ul);
	};

	$(".site-search input:text").on("keyup", function () {
		var value = $(this).val();
		if (value.length >= 2) {
			$("#keyword").attr("aria-expanded", "true");
			$("#ui-id-1").attr("role", "listbox");
			setTimeout(function () {
				$("#ui-id-1")
					.children()
					.each(function () {
						$(".ui-menu-item").attr("role", "option");
					});
			}, 500);
		} else {
			$("#keyword").attr("aria-expanded", "false");
		}
	});

	$("#Phone").keyup(function () {
		var theNumber = $(this).val().split("-").join("");
		theNumber = theNumber.match(new RegExp(".{1,4}$|.{1,3}", "g")).join("-");
		$(this).val(theNumber);
	});
	if ($(".cad-cta")[0]) {
		var windowHeight = $(window).height();
		var theHeight = -windowHeight;
		$(".cad-cta").css("opacity", "1");
		$(".cad-cta").siblings(".frame").css("margin-top", theHeight);
		$(".frame")
			.delay(1200)
			.queue(function (next) {
				$(this).animate({ marginTop: "0" }, 1000);
				next();
			});
	}
	$(".cad-cta .icon-close").on("click", function () {
		var windowHeight = $(window).height();
		var theHeight = -windowHeight;
		$(".cad-cta").siblings(".frame").animate({ marginTop: theHeight }, 1000);
	});
	$(".hover-content").hide();
	$(".wire-title").hide();
	// Headliner Full Size Image as Background
	$(".card.image")
		.not(".animated")
		.each(function () {
			$(this).css(
				"background-image",
				"url(" + $(this).find("img").prop("src") + ")"
			);
		});
	if (!expEditor) {
		$(".headline-cards .card").each(function () {
			if ($(this).has("a").length) {
				$(this).addClass("has-link");
			}
		});
		$(".card.has-link").on("click", function () {
			window.location.href = $(this).find("a").attr("href");
			return false;
		});
	}
	// Headliner Animated gif
	$(".headliner-top-hero.animated .play").on("click", function () {
		$(this).toggleClass("paused");
		$(this).closest(".animated").toggleClass("paused");
	});
	// Image Card Animated gif
	$(".card.image.animated").each(function () {
		$(this).css(
			"background-image",
			"url(" + $(this).find("img").prop("src") + ")"
		);
	});
	$(".card.image.animated .play").on("click", function () {
		var animatedImage = $(this)
			.siblings(".animated-image")
			.find("img")
			.prop("src");
		var pausedImage = $(this).siblings(".paused-image").find("img").prop("src");
		$(this).closest(".card.image").toggleClass("paused");
		$(this).toggleClass("paused");
		if ($(this).closest(".card.image").hasClass("paused")) {
			$(this)
				.closest(".card.image")
				.css("background-image", "url(" + pausedImage + ")");
		} else {
			$(this)
				.closest(".card.image")
				.css("background-image", "url(" + animatedImage + ")");
		}
	});
	// Reward Card Carousel
	$(".reward-frame").slick({
		arrows: true,
		dots: true
	});

	if (
		document.querySelector(".iphone") &&
		document.querySelector(".location-results")
	) {
		var elem = document.querySelector(".location-results");
		//map fix
		elem.classList.add("iPhone-fix");
		elem.classList.remove("location-results");
	}

	// Sticky Navigation for menus
	var navHeight = $(".header-frame header").outerHeight();
	var $stickyWrap = $("#menu-sticky-wrap");
	var $stickyNavEl = $("#menu-sticky");

	function stickyMenu() {
		if ($stickyNavEl.length > 0) {
			var scrollY = window.pageYOffset;
			var offset = $stickyWrap.offset().top;

			// make nav stick when scrolling passed
			if (navHeight + scrollY >= offset) {
				// set top position based on height of main nav and add sticky class
				$stickyNavEl.css("top", navHeight);
				$stickyNavEl.addClass("menu-sticky--active");
			} else {
				// reset top position and remove class
				$stickyNavEl.removeClass("menu-sticky--active");
				$stickyNavEl.css("top", 0);
			}
		}
	}

	function scrollWatch() {
		if ($stickyNavEl.length > 0) {
			var linkEls = $(".ms-link");
			// var scrollY = navHeight + window.pageYOffset;
			var customScrollY = navHeight + 80 + window.pageYOffset;

			for (var i = 0, l = sectionOffsets.length; i < l; i++) {
				var offset = sectionOffsets[i];
				// check offset of next section
				// if last section set next section to a really large number (or $(document).height())
				var nextOffset =
					sectionOffsets[i + 1] !== undefined ? sectionOffsets[i + 1] : 1000000;

				// if scrolling within a section
				if (customScrollY > offset && customScrollY < nextOffset) {
					// set active class, and remove previous classes accordingly
					linkEls.removeClass("mslink--active");
					targetLinks[i].addClass("mslink--active");
				}

				// if scroll is less than first section offset, clear all active classes
				if (customScrollY < sectionOffsets[0]) {
					linkEls.removeClass("mslink--active");
				}
			}
		}
	}

	// prevents scroll jump when focusing an element
	function cursorFocus(elem) {
		var x = window.pageXOffset,
			y = window.pageYOffset;
		// focus
		elem.focus();
		// then scroll back to where u shouldve been
		window.scrollTo(x, y);
	}

	function jumpTo(target, speed) {
		var $targetDiv = $('[data-anchor="' + target + '"]');
		// calculate offset (subtract-main nav height and sticky-nav height)
		var targetOffset = $targetDiv.offset().top - navHeight - 75;
		// animate scroll to corresponding target div, minus any offsets
		$("html, body").animate({ scrollTop: targetOffset }, speed, function () {
			// focus scrolled-to div
			// $targetDiv.next('.menu-card').find('.mc-linkoverlay').focus();
			cursorFocus($targetDiv.next(".menu-card").find(".mc-linkoverlay"));
		});
	}

	function checkHash() {
		// if sticky nav exists jump to hashlink target
		if ($stickyNavEl.length > 0 && window.location.hash) {
			setTimeout(function () {
				jumpTo(window.location.hash, 0);
			}, 0);
		}
	}

	var sectionOffsets = [];
	var targetLinks = [];
	$(".menu-grid-heading").each(function () {
		var $this = $(this);
		var offset = $this.offset().top;
		var targetLink = $('a[href="' + $this.attr("data-anchor") + '"]');

		targetLinks.push(targetLink);
		sectionOffsets.push(offset);
	});

	// Scroll to navigation
	var $jumpLinks = $("#menu-sticky .ms-link");
	$jumpLinks.on("click", function () {
		var target = $(this).attr("href");
		// jump to target div
		jumpTo(target, 400);
	});

	//Scroll from main menu
	//if sticky nav exists
	if ($stickyNavEl.length > 0) {
		var $navLinks = $(".subnav > ul > li > a");
		$navLinks.on("click", function () {
			// get hash string
			var hashLink = $(this).prop("hash");
			// decode special characters
			var target = decodeURI(hashLink);
			// jump to target div
			jumpTo(target, 400);
		});
	}

	// allergen filter text toggle
	var $toggle = $("#mf-text-toggle");
	$toggle.on("click keypress", function (e) {
		e.stopPropagation();
		// check for Enter click
		if (e.which === 13 || e.type === "click") {
			$("#mf-text").stop().slideToggle(300);
		}
	});

	function pdpAccord(el) {
		var $this = $(el);
		// toggle visibility/class
		$this.toggleClass("pdp-accord--open");
		$this.next().stop().slideToggle(300);
		// ada stuff
		if ($this.hasClass("pdp-accord--open")) {
			$this.attr("aria-expanded", "true");
		} else {
			$this.attr("aria-expanded", "false");
		}
	}

	// PDP - new
	var $pdpPage = $("#pdp");
	var isPdp = $pdpPage.length > 0;

	if (isPdp) {
		// main accordion (using ids for ada)
		var $pdpAccord = $("#pdp-accord-heading, #pdp-ingredients-accord-heading");
		$pdpAccord.on("click", function (e) {
			pdpAccord(this);
		});
	}

	// sticky pdp -- only for ie
	// otherwise no js is required -> position: sticky (css only)
	var $sticky = $("#pdp-sticky");
	var $pdpPage = $(".pdp__page");
	function stickyPdp() {
		if (isPdp && $("html").hasClass("ie")) {
			var scrollY = window.pageYOffset;
			var stickyHeight = $sticky.outerHeight();
			var pageHeight = $pdpPage.outerHeight();

			// absolute when at bottom of page
			if (stickyHeight + scrollY >= pageHeight) {
				$sticky.addClass("pdp__sticky--absolute");
			} else {
				// fixed when not at bottom of page
				$sticky.removeClass("pdp__sticky--absolute");
			}
		}
	}
	stickyPdp();

	function stickyNutriAllergNavBar() {
		$(".home-menu").addClass("lock");

		var scrollY = window.pageYOffset;

		if (scrollY <= 0) {
			$(".home-menu").removeClass("lock");
		}

		//only target nutrients-table

		if ($('.home-menu li a[href="#MOBILE_BREAKFAST-nutrition"]').length > 0) {
			$(".nutrients-table").each(function (i) {
				var elem = $(this);
				navBarUnderline_Nutrients(elem, i);
			});
		} else {
			$(".allergens-table").each(function (i) {
				var elem = $(this);
				navBarUnderline_Nutrients(elem, i);
			});
		}

		//limit scroll-x to main body when reaching bottom, to prevent footer scrolling
		if (
			$(window).scrollTop() + $(window).height() >
			$(document).height() - 700
		) {
			$(".flex-wrapper").addClass("overflow-auto");
		} else {
			$(".flex-wrapper").removeClass("overflow-auto");
		}
	}

	function navBarUnderline_Nutrients(elem, index) {
		var navHeight = $(".header-frame header").outerHeight(),
			scrollY = window.pageYOffset + 200,
			offset = elem.offset().top;

		if (navHeight + scrollY >= offset) {
			$(".home-menu li a.selected").removeClass("selected");
			$(".home-menu li.selected").removeClass("selected");
			$(".home-menu li a").eq(index).addClass("selected");
			$(".home-menu li").eq(index).addClass("selected");
		}
	}

	// perform animated scrolling by getting top-position of target-element and set it as scroll target
	function jumpToNutrientsLink(targetElem, addOffset) {
		// perform animated scrolling by getting top-position of target-element and set it as scroll target
		var targetOffset = $(targetElem).offset().top + addOffset;
		$("html, body").animate(
			{
				scrollTop: targetOffset
			},
			1000,
			function (e) {
				//e.preventDefault();
				//location.hash = targetElem; //attach the hash (#jumptarget) to the pageurl
			}
		);
	}

	//CFA-One Pg: add .last class for last instance of block_img_header_txt component
	var cfaOnePg = document.querySelector(".cfaOne-program");
	var giftCards = document.querySelector(".gift-cards");
	if (cfaOnePg || giftCards) {
		var blockImgDivs = document.querySelectorAll(".block_img_header_txt");
		blockImgDivs[blockImgDivs.length - 1].classList.add("last");
	}

	// global scroll event (add scroll functions in here)
	// TODO: refactor other scroll event listeners - there should only be one!
	$(window).on("scroll", function () {
		// sticky Navigation for menus
		stickyMenu();
		// set active Links in Navigation on scroll of menu
		scrollWatch();
		// sticky pdp -- only ie
		stickyPdp();
	});

	// global onload
	$(window).on("load", function () {
		// check for hashlinks and jumpto accordingly on load
		checkHash();
	});

	// TODO: Remove this or refocus it, the "Email" selector is too general!
	// Create account better error compliance for ADA
	// ------------------------------------
	$("#Email").blur(function () {
		$("#Email-error").attr("tabindex", "0");
		$("#Email-error").focus();
	});

	$("#ConfirmEmail").blur(function () {
		$("#ConfirmEmail-error").attr("tabindex", "0");
		$("#ConfirmEmail-error").focus();
	});
	// Create account better error compliance for ADA end
	// ------------------------------------

	// Grand Openings -------------------------------------------------------------------
	$(".check-zipcode").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "GrandOpenings-ZipCodeCheck"
		});
	});

	$(".location-title-dl").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "GrandOpenings-LocationTile"
		});
	});

	// Grand Openings -------------------------------------------------------------------
});
