/* global MpAnalytics:writable */

// mParticle
var MpAnalytics = (function () {

	function checkCartForClear() {
		var count = $(".cart-toggle .count span").text() || 0;
		var mParticleCartProducts = "";

		if (
			window.mParticle.persistence != null &&
			window.mParticle.persistence != "")
		{
			mParticleCartProducts =
				window.mParticle.persistence.getLocalStorage().cp || [];

			if (count < 1 && mParticleCartProducts.length > 0) {
				window.mParticle.eCommerce.Cart.clear();
			}
		}
	}

	function mParticleCreateProduct(cartModel) {
		if (
			window.mParticle.eCommerce == null ||
			window.mParticle.eCommerce == "undefined")
		{
			return null;
		}

		return window.mParticle.eCommerce.createProduct(
			cartModel.name,
			cartModel.sku,
			cartModel.price,
			cartModel.quantity,
			"", "", "", 0, "",
			{
				"Customization SKU Add": cartModel.options,
				"Packed meal upsell": cartModel.upsell
			});
	}

	function mParticleAddToCart(cartModel, logEvent) {
		checkCartForClear();
		var mpProduct = mParticleCreateProduct(cartModel);

		if (
			window.mParticle.eCommerce != null &&
			window.mParticle.eCommerce != "undefined" &&
			mpProduct != null &&
			mpProduct != "undefined")
		{
			window.mParticle.eCommerce.setCurrencyCode("USD");
			window.mParticle.eCommerce.Cart.add(mpProduct, logEvent);
		}
	}

	function mParticleRemoveFromCart(cartModel, logEvent) {
		var mpProduct = mParticleCreateProduct(cartModel);

		if (
			window.mParticle.eCommerce != null &&
			window.mParticle.eCommerce != "undefined" &&
			mpProduct != null &&
			mpProduct != "undefined")
		{
			window.mParticle.eCommerce.setCurrencyCode("USD");
			window.mParticle.eCommerce.Cart.remove(mpProduct, logEvent);
		}
	}

	// view product
	function mParticleViewProduct(cartModel) {
		window.mParticle.ready(function () {
			var product = mParticleCreateProduct(cartModel);
			if (
				window.mParticle.eCommerce != null &&
				window.mParticle.eCommerce != "undefined" &&
				product != null &&
				product != "undefined")
			{
				window.mParticle.eCommerce.logProductAction(
					window.mParticle.ProductActionType.ViewDetail,
					product);
			}
		});
	}

	// Event Type "Other"
	function mParticleLogEventOther(eventName) {
		if (window.mParticle.EventType) {
			window.mParticle.logEvent(
				eventName,
				window.mParticle.EventType.Other);
		}
	}

	// Transactions
	function mParticleLogTransaction(eventName) {
		if (
			window.mParticle.EventType != null &&
			window.mParticle.EventType != "undefined")
		{
			window.mParticle.logEvent(
				eventName,
				window.mParticle.EventType.Transaction);
		}
	}

	return {
		addToCart: mParticleAddToCart,
		logEvent: mParticleLogEventOther,
		logTransaction: mParticleLogTransaction,
		removeFromCart: mParticleRemoveFromCart,
		viewProduct: mParticleViewProduct
	};

})();

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
	module.exports = MpAnalytics;
}
