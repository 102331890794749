import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class PdpPwoCta extends Component {
	pdpCtaList: HTMLElement | null;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.pdpCtaList = this._element.querySelector(
			"[data-element='btn-container']"
		);

		if (this.pdpCtaList) {
			this.handleCtaUiUpdate();
		}
	}

	handleCtaUiUpdate(): void {
		switch (this.pdpCtaList?.childElementCount) {
			case 1:
				this._element.classList.add("wideView");
				this.pdpCtaList.classList.add("wideView");
				this.pdpCtaList.setAttribute("data-child-count", "1");
				break;
			default:
				return;
		}
	}
}

ComponentFactory.registerComponent("pdpPwoCta", PdpPwoCta);
