$(function ($) {
	// begin payment
	$(".cp-content__cards .cp-content__radio span").on("click", function () {
		$.magnificPopup.open({
			items: {
				src: "#" + $(this).attr("data-lightbox"),
				type: "inline"
			}
		});
	});

	$(".remove-card-yes").on("click", function (e) {
		e.preventDefault();

		if ($(this).hasClass("disabled")) {
			return;
		}

		$(this).addClass("disabled");
		$("cc-error").remove();

		removeCreditCard(
			$(this).attr("data-token"),
			$(this),
			removeCreditCardCallback,
			$(this).attr("data-paypal")
		);
	});

	function removeCreditCard(token, removeButton, callbackfn, paypal) {
		$.ajax({
			url: "/OnlineOrdering/Checkout/DeleteCard",
			type: "POST",
			data: {
				token: token
			},
			success: function (data) {
				callbackfn(data, token, removeButton, paypal);
			},
			error: function () {
				$(removeButton).removeClass("disabled");
				$(removeButton)
					.parent(".options")
					.siblings(".icon-close")
					.after('<p class="cc-error">' + $("#remove-cc-error").val() + "</p>");
			}
		});
	}

	function removeCreditCardCallback(data, token, removeButton, paypal) {
		$(removeButton).removeClass("disabled");

		if (data.success) {
			// show paypal if type was paypal being removed
			if (paypal == "True") {
				$(".paypal-wrapper").show();
			}

			$(
				".cp-content__cards .cp-content__radio[data-token=" + token + "]"
			).remove();
			$.magnificPopup.close();
			if ($(".cp-content__cards .cp-content__radio").length < 5) {
				$(".cp-content__messaging").remove();
			}
		} else {
			$(removeButton)
				.parent(".options")
				.siblings(".icon-close")
				.after('<p class="cc-error">' + $("#remove-cc-error").val() + "</p>");
		}
	}

	if ($("#iframeDomain").length) {
		$(function () {
			if (window.addEventListener) {
				window.addEventListener("message", displayMessage, false);
			} else {
				window.attachEvent("onmessage", displayMessage);
			}

			function displayMessage(evt) {
				var origin = evt.origin || evt.originalEvent.origin;

				var iframeDomain = $("#iframeDomain").val();
				if (origin !== iframeDomain) {
					return;
				}

				if (evt.data !== "Ready") {
					if (typeof evt.data.resultCode !== "undefined") {
						if (evt.data.resultCode == "0000") {
							$("#payment-iframe").attr("src", "");
							$.magnificPopup.close();
							confirmTxn(evt.data);
						}
					}
				}
			}

			function confirmTxn(msg) {
				if (msg.paymentToken) {
					$("#TransArmorToken").val(msg.paymentToken);
					$("#ResultCode").val(msg.resultCode);
					$("#ResultMessage").val(msg.resultMessage);

					$("#add-credit-card").submit();
				} else {
					$.ajax({
						type: "POST",
						url: "/OnlineOrdering/Checkout/PaymentError",
						data: {
							CorrelationID: $("#CorrelationID").val(),
							CUID: $("#CUID").val(),
							ResultCode: msg.resultCode,
							ResultMessage: msg.resultMessage,
							TransArmorToken: msg.paymentToken
						},
						success: function (result) {
							if (result.Success) {
							} else {
								$("#payment-error-message").text(result.ErrorMessage);
								$.magnificPopup.open({
									items: {
										src: "#payment-error",
										type: "inline"
									}
								});
							}
						},
						error: function (jqXHR, exception) {
							$.magnificPopup.open({
								items: {
									src: "#payment-error",
									type: "inline"
								}
							});
						}
					});
				}
			}
		});
	}
	// end payment

	// begin cancel order
	$(".cancel-order-btn").on("click", function () {
		$("#cancel-pending-order .cancel-order").attr(
			"data-orderid",
			$(this).attr("data-orderid")
		);

		$.magnificPopup.open({
			items: {
				src: "#cancel-pending-order",
				type: "inline"
			}
		});
	});

	$(".cancel-order").on("click", function () {
		if ($(this).hasClass("disabled")) {
			return;
		}

		$(".cancel-order-error").html("");
		$(this).addClass("disabled");

		var cancelOrderUrl =
			$(this).attr("data-href") + $(this).attr("data-orderid");

		CancelPendingOrder(cancelOrderUrl, $(this), CancelPendingOrderCallback);
	});

	function CancelPendingOrder(url, removeButton, callbackfn) {
		$.ajax({
			url: url,
			cache: false,
			dataType: "json",
			success: function (data) {
				callbackfn(data, removeButton);
			},
			error: function () {
				$(".cancel-order-error").html(
					$(".cancel-order-error").attr("data-msg")
				);
				$(removeButton).removeClass("disabled");
			}
		});
	}

	function CancelPendingOrderCallback(data, removeButton) {
		$(removeButton).removeClass("disabled");

		if (data.Success) {
			$(".cancel-order-error").html("");
			if ($("#pending-orders").html() === "1") {
				$("#pending-orders").remove();
			} else {
				$("#pending-orders").html($("#pending-orders").html() - 1);
			}

			$(
				'.cancel-order-btn[data-orderid="' +
					$(removeButton).attr("data-orderid") +
					'"]'
			)
				.parents(".cp-block")
				.remove();

			if ($(".cp-block").length == 0) {
				$(".cp-nav__card").remove();
				$("#new-order").show();
			} else if (
				$(".cp-nav__card").attr("data-orderid") ==
				$(removeButton).attr("data-orderid")
			) {
				UpdateNavPendingOrder();
			}

			$.magnificPopup.close();

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "cancelorderPendingOrders"
			});
		} else {
			$(".cancel-order-error").html(data.ErrorMessage);
		}
	}

	function UpdateNavPendingOrder() {
		$.ajax({
			url:
				"/onlineordering/oloaccount/getpendingorder?dataSource=" +
				$(".cp-nav__card").attr("data-dataSourceId"),
			cache: false,
			dataType: "json",
			success: function (data) {
				$(".cp-nav__card").replaceWith(data.Message);
			},
			error: function () {}
		});
	}
	//end cancel order

	//begin profile
	$("body").on("click", ".remove-secondary", function (e) {
		e.preventDefault();
		var deletedEmail = $(this).siblings("input").val();
		if ($('input[name="DeleteEmails"]').length) {
			$('input[name="DeleteEmails"]').val(
				$('input[name="DeleteEmails"]').val() + "|||||" + deletedEmail
			);
		} else {
			$("#edit-profile").append(
				'<input type="hidden" name="DeleteEmails" value="' +
					deletedEmail +
					'" />'
			);
		}
		$(this).parent(".cp-form__group").remove();

		if ($('input[name="SecondaryEmails"]').length < 2) {
			$(".add-secondary").parent().show();
		}
	});
	$(".add-secondary").on("click", function (e) {
		e.preventDefault();
		$(this)
			.parent()
			.after(
				'<div class="cp-form__group cp-form__group--input cp-form__group--editable"><label for="SecondaryEmails">Secondary Email</label><input value="" id="SecondaryEmails" name="SecondaryEmails" placeholder="you@email.com" type="email"><button class="cp-form__inline-btn remove-secondary">Remove</button></div>'
			);
		if ($('input[name="SecondaryEmails"]').length >= 2) {
			$(this).parent().hide();
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "addemailEditProfile"
		});
	});
	$("#edit-profile").submit(function (e) {
		if ($('input[name="SecondaryEmails"]:not([disabled])').length) {
			$('input[name="AddedEmails"]').val("");
			$('input[name="SecondaryEmails"]:not([disabled])').each(function (
				index,
				element
			) {
				if ($(this).val()) {
					$('input[name="AddedEmails"]').val(
						$('input[name="AddedEmails"]').val() + "|||||" + $(this).val()
					);
				}
			});
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "saveEditProfile"
		});
	});

	$("#notify-email-global").on("change", function () {
		var checked = $(this).is(":checked");
		$("#GlobalEmails").val(checked);
		if (checked) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailglobalNotificationOn"
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailglobalNotificationOff"
			});
		}
	});

	$("#notify-email-treats").on("change", function () {
		var checked = $(this).is(":checked");
		$("#TreatsEmails").val(checked);
		if (checked) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailtreatsNotificationOn"
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailtreatsNotificationOff"
			});
		}
	});

	$("#notify-email-national-promos").on("change", function () {
		var checked = $(this).is(":checked");
		$("#NationalEmails").val(checked);
		if (checked) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailpromoNotificationOn"
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emailpromoNotificationOff"
			});
		}
	});

	$("#notify-email-local").on("change", function () {
		var checked = $(this).is(":checked");
		$("#LocalEmails").val(checked);
		if (checked) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emaillocalNotificationOn"
			});
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "emaillocalNotificationOff"
			});
		}
	});

	$("body").on("submit", "#email-form", function (e) {
		e.preventDefault();

		if ($("#edit-primary-email").hasClass("disabled")) {
			return false;
		}

		$("#edit-primary-email").addClass("disabled");
		$("#edit-primary-email").prop("disabled", "disabled");
		$("#primaryEmailError").remove();
		editPrimaryEmail(
			$("#NewPrimaryEmail").val(),
			$("#edit-primary-email"),
			editPrimaryEmailCallback
		);
	});

	function editPrimaryEmail(newEmail, removeButton, callbackfn) {
		$.ajax({
			url: "/OnlineOrdering/OloAccount/EditPrimaryEmail",
			type: "POST",
			data: {
				newEmail: newEmail
			},
			success: function (data) {
				callbackfn(data, removeButton);
			},
			error: function () {
				$(removeButton).removeClass("disabled");
				$(removeButton).prop("disabled", false);
				$("#NewPrimaryEmail").after(
					'<p id="primaryEmailError" class="error form-err-msg">' +
						$("#email-form").attr("data-error") +
						"</p>"
				);
			}
		});
	}

	function editPrimaryEmailCallback(data, removeButton) {
		$(removeButton).removeClass("disabled");
		$(removeButton).prop("disabled", false);

		if (data.Success) {
			if ($(".success-msg").length <= 0) {
				$(".cp-content__header").append('<p class="success-msg"></p>');
			}

			$(".success-msg").html($("#email-form").attr("data-success"));
			$(".success-msg").text(
				$(".success-msg").text().replace("{email}", $("#NewPrimaryEmail").val())
			);
			$("#modal-edit-email").removeClass("cp-modal__wrap--visible");
			$("#modal-edit-email").attr("aria-hidden", "true");
			$("body").removeClass("no-scroll");
			$(window).scrollTop(0);
		} else {
			$("#NewPrimaryEmail").after(
				'<p id="primaryEmailError" class="error form-err-msg">' +
					$("#email-form").attr("data-error") +
					"</p>"
			);
		}
	}

	$("#email-form").validate({
		rules: {
			NewPrimaryEmail: {
				required: true,
				email: true,
				maxlength: 100,
				validateEmail: true
			}
		},
		errorElement: "p",
		errorPlacement: editProfileErrorPlacement
	});

	$("#email-form").on("blur keyup change paste", "input", function (event) {
		$("#primaryEmailError").remove();
		validateEmailForm("#email-form");
	});

	function validateEmailForm(id) {
		var valid = $(id).validate().checkForm();
		if (valid) {
			$("#email-form #edit-primary-email").prop("disabled", false);
			$("#email-form #edit-primary-email").removeClass("disabled");
		} else {
			$("#email-form #edit-primary-email").prop("disabled", "disabled");
			$("#email-form #edit-primary-email").addClass("disabled");
		}
	}

	//end profile

	//begin Forgot to Scan

	$(".scan-datepicker").datepicker({
		changeMonth: true,
		changeYear: true,
		minDate: -1 * $(".scan-datepicker").attr("data-dateRange"),
		maxDate: 0,
		onSelect: function (value, date) {
			$(".scan-datepicker").parent().removeClass("cp-form__group--error");
			$(".scan-datepicker").removeClass("error");
			$(".scan-datepicker").siblings("p").remove();
			validateScanForm("#cp-f2s");
		}
	});

	$.validator.addMethod(
		"currency",
		function (value, element) {
			return (
				this.optional(element) ||
				/^\$?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/.test(value)
			);
		},
		"Please specify a valid amount"
	);

	$.validator.addMethod(
		"customDate",
		function (value, element) {
			return (
				this.optional(element) ||
				/^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|1\d|2\d|3[01]|[1-9])\/(19|20)\d{2}$/.test(
					value
				)
			);
		},
		"Please specify a valid date"
	);

	function dollarSignReq(input, element) {
		if ($(input).startsWith("$")) {
			return (
				this.optional(element) ||
				/^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/.test(input)
			);
		} else {
			return (
				this.optional(element) ||
				/^\(\d{1,3}(\,\d{3})*|(\d+)(\.\d{2})?/.test(input)
			);
		}
	}

	function cpErrorPlacement(error, element) {
		error.insertAfter(element);
		$(element).parent().addClass("cp-form__group--error");
	}

	function cpSuccess(label, element) {
		if (!$(element).hasClass("error")) {
			if ($(element).parent().hasClass("cp-form__group--error")) {
				$(element).siblings("p.error:first").remove();
			}
			$(element).parent().removeClass("cp-form__group--error");
		}
	}

	$("#cp-f2s").validate({
		rules: {
			SelectedCategory: "required",
			RestaurantNumber: {
				required: true,
				number: true,
				minlength: 5,
				maxlength: 5
			},
			Date: {
				required: true,
				customDate: true
			},
			OrderNumber: {
				required: true,
				number: true
			},
			OrderTotal: {
				required: true,
				maxlength: 50,
				currency: true
			}
		},
		errorElement: "p",
		errorPlacement: cpErrorPlacement,
		success: cpSuccess
	});

	$("#cp-f2s").on("blur keyup change paste", "input", function (event) {
		validateScanForm("#cp-f2s");
	});

	function validateScanForm(id) {
		var valid = $(id).validate().checkForm();
		if (valid) {
			$(".cp-form__group button").prop("disabled", false);
			$(".cp-form__group button").removeClass("btn--disabled");
		} else {
			$(".cp-form__group button").prop("disabled", "disabled");
			$(".cp-form__group button").addClass("btn--disabled");
		}
	}

	if ($("#cp-f2s").length) {
		validateScanForm("#cp-f2s");
	}

	// end Forgot to Scan

	// begin Data Layer

	// begin points
	if ($("body").find(".nge-points").length) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsmenuPoints"
		});
	}

	$(".dl-nge-qr-scan").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "pointsScan"
		});
	});

	$(".dl-nge-add-reward-btn").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "pointsAdd",
			productName: $(this).attr("data-reward-tag")
		});
	});

	$(".dl-nge-view-all-rewards").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "pointsView"
		});
	});

	$("body").on("click", ".dl-nge-reward-success-add-more", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsuccessBrowse"
		});
	});

	$("body").on("click", ".dl-nge-reward-success-my-rewards", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsuccessView"
		});
	});

	$("body").on("click", ".dl-nge-reward-success-close", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsuccessClose"
		});
	});
	// end points

	// begin my rewards
	if ($("body").find(".dl-nge-my-rewards-container").length) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsmenuMyRewards"
		});
	}

	$(".dl-nge-my-rewards-container").on(
		"click",
		".dl-nge-redeem-reward-btn",
		function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "myrewardsRedeem",
				productName: $(this).attr("data-reward-tag")
			});
		}
	);

	$(".dl-nge-scan-for-points-btn").on("click", function () {
		// Check which page this common element is on
		if ($(".cp-content__inner").find(".dl-nge-my-rewards-container").length) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "myrewardsScan"
			});
		}
	});

	$("#show-past-rewards").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "myrewardsViewPast"
		});
	});

	$("body").on("click", ".print-qr-button", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "redeemrewardPrintMembership"
		});
	});

	// end my rewards

	// begin add rewards
	if ($("body").find(".dl-nge-redeem-rewards-container").length) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "rewardsmenuRedeemPoints"
		});
	}

	$(".dl-nge-redeem-rewards-container").on(
		"click",
		".dl-nge-redeem-reward-btn",
		function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "addrewardsMyReward",
				productName: $(this).attr("data-reward-tag")
			});
		}
	);

	$("body").on("click", ".dl-nge-redeem-reward-cancel", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "redeemrewardCancel"
		});
	});

	$("body").on("click", ".dl-nge-redeem-reward-confirm", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "redeemrewardAdd"
		});
	});

	$("body").on("click", ".dl-nge-op-msg-close", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "operatornotificationClose"
		});
	});

	$("body").on("click", ".dl-nge-redeem-reward-close", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "redeemrewardClose"
		});
	});

	$(".dl-nge-cp-filters").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "addrewardsFilters"
		});
	});

	$(".dl-nge-scan-for-points-btn").on("click", function () {
		// Check which page this common element is on
		if ($(".cp-content__inner").find(".dl-nge-add-rewards-container").length) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "addrewardsScan"
			});
		}
	});
	// end add rewards

	// begin Cow Calendar --------------------------------------------------------

	$(".cp-content").on("click", ".activate-card", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "activateCowCalendar"
		});
	});

	$(".cp-content").on("click", ".access-card", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "accessCowCalendar"
		});
	});

	// end Cow Calendar ----------------------------------------------------------
	$("#PhoneNumber").on("click", function (e) {
		var phoneVal = $("#PhoneNumber").val();

		if (!phoneVal) {
			$("#PhoneNumber").val("1");
		}
	});

	// begin Edit Profile --------------------------------------------------------

	$("#edit-profile").on("click", ".change-primary-email", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "updateemailEditProfile"
		});
	});

	$("#edit-profile").on("click", ".change-password", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "changepasswordEditProfile"
		});

		$.ajax({
			url: "/OnlineOrdering/OloAccount/ResetPassword",
			type: "GET",
			cache: false,
			success: function (result) {
				if (result.success) {
					$.magnificPopup.open({
						items: {
							src: "#reset-password-success",
							type: "inline"
						}
					});
				} else {
					$.magnificPopup.open({
						items: {
							src: "#reset-password-fail",
							type: "inline"
						}
					});
				}
			},
			error: function (result) {
				$.magnificPopup.open({
					items: {
						src: "#reset-password-fail",
						type: "inline"
					}
				});
			}
		});

		return false;
	});

	$("#edit-profile").on("click", ".change-primary-email", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "updateemailEditProfile"
		});
	});

	$("#edit-profile").on("click", ".change-password", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "changepasswordEditProfile"
		});
	});

	if ($("#edit-profile").length) {
		$(".cp-nav__details h4").text($("#edit-profile").attr("data-name"));
	}

	editProfile();
	function editProfile() {
		var isEditing = false;

		$("#edit-profile").on("keypress", 'input[type="text"]', function () {
			if (!isEditing) {
				isEditing = true;

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "formEditProfile"
				});
			}
		});

		$("#edit-profile").on("change", "select", function () {
			if (!isEditing) {
				isEditing = true;

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "formEditProfile"
				});
			}
		});
	}

	$("#edit-profile").validate({
		rules: {
			FirstName: {
				required: true,
				maxlength: 100
			},
			LastName: {
				required: true,
				maxlength: 100
			},
			PhoneNumber: {
				required: false,
				phoneFormat: true
			},
			SecondaryEmails: {
				required: false,
				email: true,
				maxlength: 100,
				validateEmail: true
			}
		},
		errorElement: "p",
		errorPlacement: editProfileErrorPlacement
	});

	function editProfileErrorPlacement(error, element) {
		error.addClass("form-err-msg");
		error.insertAfter(element);
	}

	// end Edit Profile ----------------------------------------------------------

	// begin Edit Favorites ------------------------------------------------------

	$(".cp-preferred--restaurant").on(
		"click",
		"a.change-preferred-location",
		function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "locationEditFavorites"
			});
		}
	);

	$(".cp-preferred--restaurant").on(
		"click",
		"a.change-preferred-restaurant",
		function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "restaurantEditFavorites"
			});

			var locationNumber = $(this).data("location");

			$.ajax({
				url: "/OnlineOrdering/OloAccount/DeleteFavoriteRestaurant",
				type: "POST",
				cache: false,
				data: { locationNumber: locationNumber }
			}).done(function (data) {
				if (data === "True") {
					location.reload();
				}
			});
		}
	);

	// end Edit Favorites --------------------------------------------------------

	// begin Pending Orders ------------------------------------------------------

	$(".cp-block").on("click", "p.call-location", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "callrestaurantPendingOrders"
		});
	});

	$("#consolidated-profile").on("click", "a.new-order", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "neworderPendingOrders"
		});
	});

	$(".cp-block").on("click", ".edit-order-btn", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "editorderPendingOrders"
		});
	});

	$(".cp-block").on("click", ".print-pending-order", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "printorderPendingOrders"
		});
	});

	// end Pending Orders --------------------------------------------------------

	// begin Transaction History -------------------------------------------------

	$(".cp-block").on("click", ".print-receipt", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "printreceiptTransactionHistory"
		});
	});

	$(".cp-block").on("click", ".reorder", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "orderagainTransactionHistory"
		});
	});

	$(".cp-block").on("click", ".start-new-order", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "neworderTransactionHistory"
		});
	});

	$(".cp-content__header").on("click", "a.forgot-2-scan", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "creditpurchaseTransactionHistory"
		});
	});

	// begin Filter

	$(".cp-block").on("click", "#check-catering-orders", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "cateringfilterTransactionHistory"
		});
	});

	$(".cp-block").on("click", "#check-mobile-orders", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "mobilefilterTransactionHistory"
		});
	});

	$(".cp-block").on("click", "#check-funds-added", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "fundsfilterTransactionHistory"
		});
	});

	$(".cp-block").on("click", "#check-scans", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "scansfilterTransactionHistory"
		});
	});

	// end Filter
	// end Transaction History ---------------------------------------------------

	// begin Manage Payments -----------------------------------------------------

	$("body").on("click", "a.remove-card-yes", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "removecardManagePaymentMethods"
		});
	});

	// end Manage Payments -------------------------------------------------------

	// Begin Rewards-CP

	$("body").on("click", ".nge-modal", function (e) {
		e.preventDefault();
		var modalId = $(this).attr("data-modal");
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: modalId,
				type: "inline"
			}
		});
	});

	$("body").on("click", ".print-qr-button", function (e) {
		e.preventDefault();
		var qrContent = $(this).parent().html();
		var windowObj = window.open(
			"",
			"PrintWindow",
			"width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes"
		);
		var strHtml =
			"<html>\n<head>\n</head><body><div>\n" +
			qrContent +
			"\n</div>\n</body>\n</html>";
		windowObj.document.writeln(strHtml);
		windowObj.document.close();
		windowObj.focus();
		windowObj.print();
		windowObj.close();
	});

	//redeem-my-reward
	$("body").on("click", ".redeem-my-reward", function (e) {
		e.preventDefault();
		var modalId = "#nge-qr-rewards";
		$(modalId).html("");
		var rdTitle = $(this).attr("data-redeem-title");
		var rdStyle = $(this).attr("data-redeem-style");
		var rdType = $(this).attr("data-redeem-type");
		var controller = "/onlineordering/oloaccount/myrewardqrmodal";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				title: rdTitle,
				style: rdStyle,
				rewardType: rdType
			}
		}).done(function (partialViewResult) {
			$(modalId).html(partialViewResult);
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {
					src: modalId,
					type: "inline"
				}
			});
		});
	});

	//add reward
	$("body").on("click", ".add-reward-button", function (e) {
		e.preventDefault();
		var modalId = $(this).attr("data-modal");
		var tag = $(this).attr("data-reward-tag");
		var title = $(this).attr("data-reward-title");
		var imgurl = $(this).attr("data-reward-imgurl");
		var pts = $(this).attr("data-reward-points");
		var controller = "/onlineordering/oloaccount/redeemitemconfirm";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				pointCost: pts,
				rewardTag: tag,
				rewardTitle: title,
				imgUrl: imgurl
			}
		}).done(function (partialViewResult) {
			$(modalId).html(partialViewResult);
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {
					src: modalId,
					type: "inline"
				}
			});
		});
	});

	//add-reward-confirm
	$("body").on("click", ".add-reward-confirm", function (e) {
		e.preventDefault();
		var modalId = $(this).data("modal");
		var tag = $(this).data("reward-tag");
		var title = $(this).data("reward-title");
		var imgurl = $(this).data("reward-imgurl");
		var url = $(this).attr("href");
		var actionType = $(this).data("type");
		var giftToFriendDatasource = $(this).data("gifttofriend-datasource");

		var controller = "/onlineordering/oloaccount/redeemitemcomplete";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				rewardTag: tag,
				rewardTitle: title,
				imgUrl: imgurl,
				actionType: actionType,
				giftToFriendDatasource: giftToFriendDatasource
			}
		}).done(function (partialViewResult) {
			if (actionType === "AddToMyRewards") {
				$(modalId).html(partialViewResult);
				$.magnificPopup.close();
				$.magnificPopup.open({
					items: {
						src: modalId,
						type: "inline"
					}
				});
			} else if (actionType === "AddToOrder") {
				window.location = url;
			}
		});
	});

	//view-offer-message
	$("body").on("click", ".view-offer-message", function (e) {
		e.preventDefault();
		var modalId = $(this).attr("data-modal");
		var awardId = $(this).attr("data-awardId");
		var cardStyle = $(this).attr("data-reward-style");
		var controller = "/onlineordering/oloaccount/viewoffermessage";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				awardId: awardId,
				cardStyle: cardStyle
			}
		}).done(function (partialViewResult) {
			$(modalId).html(partialViewResult);
			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {
					src: modalId,
					type: "inline"
				}
			});
		});
	});

	$("body").on("click", ".reward-card", function (e) {
		var target = $(e.target);
		if (target.hasClass("slick-prev")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "leftArrow"
			});
		} else if (target.hasClass("slick-next")) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "engagement",
				category: "rightArrow"
			});
		}
	});

	//my-rewards-set
	//update My Rewards
	$("body").on("change", "#sort-select-myrewards", function () {
		var displayOrder = $("#sort-select-myrewards option:selected").val();
		var ascending = displayOrder == "true";
		updateMyRewardsMenu(displayOrder, ascending);
		updatePastRewardsMenu(displayOrder, ascending);
	});

	function updateMyRewardsMenu(displayOrder, ascending) {
		var giftingVal = $('input[name="hasGiftingValue"]').val() == "True";

		var controller = "/onlineordering/oloaccount/myrewardset";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				ascending: ascending,
				active: true,
				gifting: giftingVal
			}
		}).done(function (partialViewResult) {
			$("#my-rewards-set").html(partialViewResult);

			$("#my-rewards-set .reward-frame").slick({
				arrows: true,
				dots: true
			});
		});
	}

	function updatePastRewardsMenu(displayOrder, ascending) {
		var controller = "/onlineordering/oloaccount/myrewardset";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				ascending: ascending,
				active: false
			}
		}).done(function (partialViewResult) {
			$("#past-rewards-set").html(partialViewResult);

			$("#past-rewards-set .reward-frame").slick({
				arrows: true,
				dots: true
			});
		});
	}

	//update all rewards
	$("body").on(
		"change",
		"#sort-select, input[name = 'reward-filter-option']",
		function () {
			updateAddRewardsMenu();
		}
	);

	function updateAddRewardsMenu() {
		var displayOrder = $("#sort-select option:selected").val();
		var sectionTitle = $("input[name='rpSectionTitle']").val();
		var availPoints = $("input[name='rpAvailPoints']").val();
		var addReward = $("input[name='rpAddRewardLabel']").val();
		var lowtoHighBool = displayOrder == "true";
		var filters = [];
		$.each($("input[name='reward-filter-option']:checked"), function () {
			filters.push($(this).val());
		});
		var controller = "/onlineordering/oloaccount/rewarditemsets";
		$.ajax({
			url: controller,
			type: "GET",
			cache: false,
			data: {
				lowToHigh: lowtoHighBool,
				pointTitle: sectionTitle,
				addRewardLabel: addReward,
				availPoints: availPoints,
				filters: filters.join(",")
			}
		}).done(function (partialViewResult) {
			$("#rewardredeemset").html(partialViewResult);
		});
	}

	// end update rewards

	// form validation

	$.validator.addMethod(
		"validateEmail",
		function (value, element) {
			return (
				this.optional(element) ||
				/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,})+$/.test(
					value
				)
			);
		},
		"Please provide a valid email address"
	);

	$("body").on("blur keyup change paste", "#PersonalMessage", function (event) {
		$(".character-count").text($(this).val().length);
	});

	$("#nge-gifting").on("click", ".mfp-close", function (e) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "giftrewardClose"
		});
	});

	// edit values

	// End Rewards

	// begin News

	// add reward
	$("body").on("click", ".inbox-message-action", function (e) {
		e.preventDefault();
		var newsId = $(this).attr("data-message-id");

		var controller = "/onlineordering/oloaccount/acknowledgenewscard";
		$.ajax({
			url: controller,
			context: this,
			type: "GET",
			cache: false,
			data: {
				messageId: newsId
			},
			success: function () {
				$(this)
					.closest(".news-card__inner")
					.children(".news-card__overlay")
					.addClass(" news-card__overlay--visible");
			},
			error: function () {
				$(this).replaceWith($(".card-error-container").html());
			}
		});
	});

	// access ticket

	$(".news-card__footer a.btn-round.btn-round--white").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "accesstickets"
		});
	});

	// End News

	// begin Dashboard -----------------------------------------------------------

	$(".cp-nav__details").on("click", ".edit-pending-order-btn", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "editorderDashboardView"
		});
	});

	$(".cp-nav__details").on(
		"click",
		".cancel-order, .cancel-order-btn",
		function () {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "cancelorderDashboardView"
			});
		}
	);

	// end Dashboard -------------------------------------------------------------

	// end Data Layer

	// begin Transaction History
	$("form.cp-filter__form").on("change", "#sort-select", function () {
		var blockWrapper = $(".cp-block__wrap");
		var blocks = blockWrapper.children("div.cp-block");
		blockWrapper.append(blocks.get().reverse());
	});

	transactionHistoryFilter();

	function transactionHistoryFilter() {
		var selectedTransactionTypes = [];
		$("form.cp-filter__form").on(
			"click",
			'input[type="checkbox"]',
			function () {
				var $this = $(this);
				var transactionTypes = [];
				var data = $this.data("types");

				transactionTypes = data.split("|");

				for (var i = 0; i < transactionTypes.length; i++) {
					if (selectedTransactionTypes.indexOf(transactionTypes[i]) === -1) {
						selectedTransactionTypes.push(transactionTypes[i]);
					} else {
						if ($(this).is(":checked")) {
							selectedTransactionTypes = jQuery.grep(
								selectedTransactionTypes,
								function (value) {
									return value != transactionTypes[i];
								}
							);
						}
					}
				}

				$(".cp-block__wrap.transaction-history")
					.find(".cp-block")
					.each(function () {
						var transType = $(this).data("transactionType");
						if (~selectedTransactionTypes.indexOf(transType)) {
							$(this).hide();
						} else {
							$(this).show();
						}
					});
			}
		);
	}

	$("#Birthday").keypress(function (e) {
		var regex = new RegExp("^[0-9/]+$");
		var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
		if (regex.test(str)) {
			return true;
		}

		e.preventDefault();
		return false;
	});

	// begin account creation
	$.validator.addMethod(
		"containsAtLeastOneDigit",
		function (value) {
			return /[a-z].*[0-9]|[0-9].*[a-z]/i.test(value);
		},
		"Password must include a number and a letter."
	);
	$.validator.addMethod(
		"phoneFormat",
		function (value, element) {
			return (
				(this.optional && value.length === 0) ||
				/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
			);
		},
		"Please enter a valid phone number"
	);

	// end account creation

	// begin deactivate account
	$("#deactivate-account").on("click", function () {
		if ($(this).hasClass("btn--disabled")) {
			return;
		}

		$(this).addClass("btn--disabled");

		$.ajax({
			type: "GET", // TODO: Should this really be a GET?
			url: "/onlineordering/oloaccount/deactivateuseraccount",
			dataType: "json",
			cache: false,
			success: function (msg) {
				if (msg.Success) {
					$(".succ-msg").html($(".succ-msg").attr("data-success"));
					$(".breadcrumbs, .message, .frame .btn").remove();
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "deactivateAccountSuccess"
					});
				} else {
					$(".succ-msg").html($(".succ-msg").attr("data-error"));
					$("#deactivate-account").removeClass("btn--disabled");
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "deactivateAccountFail"
					});
				}
			},
			error: function (jqXHR) {
				if (
					jqXHR &&
					jqXHR.responseText &&
					jqXHR.responseText.includes('{"Success":true}')
				) {
					$(".succ-msg").html($(".succ-msg").attr("data-success"));
					$(".breadcrumbs, .message, .frame .btn").remove();
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "deactivateAccountSuccess"
					});
				} else {
					$(".succ-msg").html($(".succ-msg").attr("data-error"));
					$("#deactivate-account").removeClass("btn--disabled");
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "deactivateAccountFail"
					});
				}
			}
		});
	});

	$(".deactivate-cancel").on("click", function () {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "engagement",
			category: "deactivateAccountCancel"
		});
	});

	// end deactivate account

	// start Favorite Delivery Addresses
	$("body").on("click", "#add-favorite-delivery", function () {
		$("#add-favorite-delivery-address #addAddress").val("");
		$("#add-favorite-delivery-address #addAddress2").val("");
		$("#add-favorite-delivery-address #addCity").val("");
		$("#add-favorite-delivery-address #addState").val("");
		$("#add-favorite-delivery-address #addZipCode").val("");

		$("#add-delivery-address-submit").prop("disabled", "disabled");
		$("#add-delivery-address-submit").addClass("disabled");
		$("#add-favorite-delivery-address").validate().resetForm();

		var isIphone = $("body").hasClass("iphone");
		$.magnificPopup.close();

		setTimeout(function () {
			$.magnificPopup.open({
				items: {
					src: "#add-favorite-delivery-address-modal",
					type: "inline"
				},
				callbacks: {
					open: function () {
						// prevent overflow and scrolling
						// add default no-scroll class
						$("html, body").addClass("no-scroll-default");
						// add ios no-scroll class (bugfix)
						if (isIphone) $("html, body").addClass("no-scroll-ios");
					},
					close: function () {
						// remove default no-scroll class
						$("html, body").removeClass("no-scroll-default");
						// remove ios no-scroll class (bugfix)
						if (isIphone) $("html, body").removeClass("no-scroll-ios");
					}
				}
			});
		}, 0);
	});

	$("body").on("click", ".edit-location", function () {
		$("#edit-favorite-delivery-address").attr(
			"data-hash",
			$(this).parents(".cp-preferred").attr("data-hash")
		);
		$("#edit-favorite-delivery-address #editAddress").val(
			$(this).attr("data-address")
		);
		$("#edit-favorite-delivery-address #editAddress2").val(
			$(this).attr("data-address2")
		);
		$("#edit-favorite-delivery-address #editCity").val(
			$(this).attr("data-city")
		);
		$("#edit-favorite-delivery-address #editState").val(
			$(this).attr("data-state")
		);
		$("#edit-favorite-delivery-address #editZipCode").val(
			$(this).attr("data-zip")
		);

		$("#edit-delivery-address-submit").prop("disabled", "");
		$("#edit-delivery-address-submit").removeClass("disabled");
		$("#edit-favorite-delivery-address").validate().resetForm();

		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: "#edit-favorite-delivery-address-modal",
				type: "inline"
			}
		});
	});

	$("body").on("click", ".reenter-address", function () {
		var lightboxSrc = "#add-favorite-delivery-address-modal";

		if (
			$(this)
				.parents("#address-verification-overlay")
				.hasClass("edit-verification")
		) {
			lightboxSrc = "#edit-favorite-delivery-address-modal";
		}

		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: lightboxSrc,
				type: "inline"
			}
		});
	});

	$("body").on("click", ".use-brandify-favorite", function () {
		if ($("#address-verification-overlay").hasClass("edit-verification")) {
			var addressHash = $("#edit-favorite-delivery-address").attr("data-hash");
			var editAddress1 = $(
				"#edit-favorite-delivery-address #editAddress"
			).val();
			var editAddress2 = $(
				"#edit-favorite-delivery-address #editAddress2"
			).val();
			var editCity = $("#edit-favorite-delivery-address #editCity").val();
			var editState = $("#edit-favorite-delivery-address #editState").val();
			var editZip = $("#edit-favorite-delivery-address #editZipCode").val();

			$("#edit-delivery-address-submit").prop("disabled", "disabled");
			$("#edit-delivery-address-submit").addClass("disabled");

			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {
					src: "#edit-favorite-delivery-address-modal",
					type: "inline"
				}
			});

			editDeliveryAddress(
				addressHash,
				editAddress1,
				editAddress2,
				editCity,
				editState,
				editZip
			);
		} else {
			var address1 = $("#add-favorite-delivery-address #addAddress").val();
			var address2 = $("#add-favorite-delivery-address #addAddress2").val();
			var city = $("#add-favorite-delivery-address #addCity").val();
			var state = $("#add-favorite-delivery-address #addState").val();
			var zip = $("#add-favorite-delivery-address #addZipCode").val();

			$("#add-delivery-address-submit").prop("disabled", "disabled");
			$("#add-delivery-address-submit").addClass("disabled");

			$.magnificPopup.close();
			$.magnificPopup.open({
				items: {
					src: "#add-favorite-delivery-address-modal",
					type: "inline"
				}
			});

			addDeliveryAddress(address1, address2, city, state, zip);
		}
	});

	$("#add-favorite-delivery-address").validate({
		rules: {
			addAddress: {
				required: true,
				maxlength: 40
			},
			addAddress2: {
				required: false,
				maxlength: 40
			},
			addCity: {
				required: true,
				maxlength: 100
			},
			addState: {
				required: true
			},
			addZipCode: {
				required: true,
				minlength: 5,
				maxlength: 5,
				digits: true
			}
		},
		errorElement: "p",
		errorPlacement: favoriteDeliveryAddressErrorPlacement
	});

	function favoriteDeliveryAddressErrorPlacement(error, element) {
		error.addClass("form-err-msg");
		error.insertAfter(element);
	}

	$("#add-favorite-delivery-address").on(
		"blur keyup change paste",
		"input, select",
		function (event) {
			validateFavoriteDeliveryAddressForm("#add-favorite-delivery-address");
		}
	);

	function validateFavoriteDeliveryAddressForm(id) {
		var valid = $(id).validate().checkForm();
		if (valid) {
			$("#add-delivery-address-submit").prop("disabled", false);
			$("#add-delivery-address-submit").removeClass("disabled");
		} else {
			$("#add-delivery-address-submit").prop("disabled", "disabled");
			$("#add-delivery-address-submit").addClass("disabled");
		}
	}

	$("#add-delivery-address-submit").on("click", function () {
		if (!$(this).prop("disabled")) {
			var address1 = $("#add-favorite-delivery-address #addAddress").val();
			var address2 = $("#add-favorite-delivery-address #addAddress2").val();
			var city = $("#add-favorite-delivery-address #addCity").val();
			var state = $("#add-favorite-delivery-address #addState").val();
			var zip = $("#add-favorite-delivery-address #addZipCode").val();
			validateDeliveryAddress(address1, address2, city, state, zip, false);
		}
	});

	function validateDeliveryAddress(
		address1,
		address2,
		city,
		state,
		zip,
		isEdit
	) {
		var address =
			address1 + " " + address2 + ", " + city + " " + state + " " + zip;
		if (isEdit) {
			$("#edit-favorite-delivery-address-modal .g-messaging").hide();
		} else {
			$("#add-favorite-delivery-address-modal .g-messaging").hide();
		}

		$.ajax({
			type: "POST",
			url: "/OnlineOrdering/OloLocation/GeocodeDeliveryAddress",
			data: {
				address: address,
				favoriteAddress: true
			},
			success: function (result) {
				if (result.Success) {
					if (isEdit) {
						var addressHash = $("#edit-favorite-delivery-address").attr(
							"data-hash"
						);
						editDeliveryAddress(
							addressHash,
							address1,
							address2,
							city,
							state,
							zip
						);
					} else {
						addDeliveryAddress(address1, address2, city, state, zip);
					}
				} else {
					if (!$("#address-verification-overlay").length) {
						$(".cp-content").append(
							'<div id="address-verification-overlay"></div>'
						);
					}

					$("#address-verification-overlay").html(result.HtmlResult);
					$("#address-verification-overlay .icon-close").remove();

					if (isEdit) {
						$("#address-verification-overlay").addClass("edit-verification");
					} else {
						$("#address-verification-overlay").removeClass("edit-verification");
					}

					$.magnificPopup.close();
					$.magnificPopup.open({
						items: {
							src: "#address-verification-overlay",
							type: "inline"
						}
					});
				}
			},
			error: function (jqXHR, exception) {
				if (isEdit) {
					$("#edit-favorite-delivery-address-modal .g-messaging").show();
				} else {
					$("#add-favorite-delivery-address-modal .g-messaging").show();
				}
			}
		});
	}

	$("body").on(
		"change",
		"#address-verification-overlay input[name=addressSelect]",
		function () {
			var selected = $(
				"#address-verification-overlay input[name=addressSelect]:checked"
			);

			if ($("#address-verification-overlay").hasClass("edit-verification")) {
				$("#edit-favorite-delivery-address #editAddress").val(
					selected.attr("data-address")
				);
				$("#edit-favorite-delivery-address #editAddress2").val(
					selected.attr("data-address2")
				);
				$("#edit-favorite-delivery-address #editCity").val(
					selected.attr("data-city")
				);
				$("#edit-favorite-delivery-address #editState").val(
					selected.attr("data-state")
				);
				$("#edit-favorite-delivery-address #editZipCode").val(
					selected.attr("data-zip")
				);
			} else {
				$("#add-favorite-delivery-address #addAddress").val(
					selected.attr("data-address")
				);
				$("#add-favorite-delivery-address #addAddress2").val(
					selected.attr("data-address2")
				);
				$("#add-favorite-delivery-address #addCity").val(
					selected.attr("data-city")
				);
				$("#add-favorite-delivery-address #addState").val(
					selected.attr("data-state")
				);
				$("#add-favorite-delivery-address #addZipCode").val(
					selected.attr("data-zip")
				);
			}

			$(".use-brandify-favorite").removeClass("disabled");
		}
	);

	function addDeliveryAddress(address1, address2, city, state, zip) {
		$("#add-favorite-delivery-address-modal .g-messaging").hide();
		$.ajax({
			type: "POST",
			url: "/OnlineOrdering/OloAccount/AddFavoriteDeliveryAddress",
			data: {
				Address: address1,
				Address2: address2,
				City: city,
				State: state,
				Zip: zip,
				Datasource: $("#FavoriteAddressDatasource").val()
			},
			success: function (result) {
				if (result.Success) {
					$(".cp-preferred__wrapper").append(result.AddressHtml);
					$("#add-favorite-delivery").text(
						$("#add-favorite-delivery").attr("data-more")
					);

					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "saveDeliveryFavorites"
					});

					$.magnificPopup.close();
				} else {
					$("#add-favorite-delivery-address-modal .g-messaging").show();
					$("#add-delivery-address-submit").prop("disabled", "");
					$("#add-delivery-address-submit").removeClass("disabled");
				}
			},
			error: function (jqXHR, exception) {
				$("#add-favorite-delivery-address-modal .g-messaging").show();
				$("#add-delivery-address-submit").prop("disabled", "");
				$("#add-delivery-address-submit").removeClass("disabled");
			}
		});
	}

	$("body").on("click", ".close-lightbox", function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});

	$("body").on("click", ".delete-address", function () {
		$.magnificPopup.close();
		$.magnificPopup.open({
			items: {
				src: $(this).attr("data-lightboxid"),
				type: "inline"
			}
		});
	});

	$("body").on("click", ".remove-address-yes", function () {
		if (!$(this).hasClass("disabled")) {
			$(".remove-address-yes").addClass("disabled");
			deleteDeliveryAddress($(this).attr("data-hash"), this);
		}
	});

	function deleteDeliveryAddress(addressHash, button) {
		$(button).parents(".lightbox").find(".delete-error").hide();
		$.ajax({
			type: "POST",
			url: "/OnlineOrdering/OloAccount/DeleteFavoriteDeliveryAddress",
			data: {
				AddressHash: addressHash
			},
			success: function (result) {
				$(".remove-address-yes").removeClass("disabled");
				if (result.Success) {
					$(".cp-preferred--location[data-hash=" + addressHash + "]").remove();
					if ($(".cp-preferred--location").length === 0) {
						$("#add-favorite-delivery").text(
							$("#add-favorite-delivery").attr("data-none")
						);
					}
					$.magnificPopup.close();
				} else {
					$(button).parents(".lightbox").find(".delete-error").show();
				}
			},
			error: function (jqXHR, exception) {
				$(".remove-address-yes").removeClass("disabled");
				$(button).parents(".lightbox").find(".delete-error").show();
			}
		});
	}

	$("#edit-favorite-delivery-address").validate({
		rules: {
			editAddress: {
				required: true,
				maxlength: 40
			},
			editAddress2: {
				required: false,
				maxlength: 40
			},
			editCity: {
				required: true,
				maxlength: 100
			},
			editState: {
				required: true
			},
			editZipCode: {
				required: true,
				minlength: 5,
				maxlength: 5,
				digits: true
			}
		},
		errorElement: "p",
		errorPlacement: favoriteDeliveryAddressErrorPlacement
	});

	$("#edit-favorite-delivery-address").on(
		"blur keyup change paste",
		"input, select",
		function (event) {
			validateEditFavoriteDeliveryAddressForm(
				"#edit-favorite-delivery-address"
			);
		}
	);

	function validateEditFavoriteDeliveryAddressForm(id) {
		var valid = $(id).validate().checkForm();
		if (valid) {
			$("#edit-delivery-address-submit").prop("disabled", false);
			$("#edit-delivery-address-submit").removeClass("disabled");
		} else {
			$("#edit-delivery-address-submit").prop("disabled", "disabled");
			$("#edit-delivery-address-submit").addClass("disabled");
		}
	}

	$("#edit-delivery-address-submit").on("click", function () {
		if (!$(this).prop("disabled")) {
			var address1 = $("#edit-favorite-delivery-address #editAddress").val();
			var address2 = $("#edit-favorite-delivery-address #editAddress2").val();
			var city = $("#edit-favorite-delivery-address #editCity").val();
			var state = $("#edit-favorite-delivery-address #editState").val();
			var zip = $("#edit-favorite-delivery-address #editZipCode").val();
			validateDeliveryAddress(address1, address2, city, state, zip, true);
		}
	});

	function editDeliveryAddress(
		addressHash,
		address1,
		address2,
		city,
		state,
		zip
	) {
		$("#edit-favorite-delivery-address-modal .g-messaging").hide();
		$.ajax({
			type: "POST",
			url: "/OnlineOrdering/OloAccount/EditFavoriteDeliveryAddress",
			data: {
				AddressHash: addressHash,
				Address: address1,
				Address2: address2,
				City: city,
				State: state,
				Zip: zip,
				Datasource: $("#FavoriteAddressDatasource").val()
			},
			success: function (result) {
				if (result.Success) {
					$(".cp-preferred--location[data-hash=" + addressHash + "]").remove();
					$(".cp-preferred__wrapper").append(result.AddressHtml);
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "engagement",
						category: "saveDeliveryFavorites"
					});
					$.magnificPopup.close();
				} else {
					$("#edit-favorite-delivery-address-modal .g-messaging").show();
					$("#edit-delivery-address-submit").prop("disabled", "");
					$("#edit-delivery-address-submit").removeClass("disabled");
				}
			},
			error: function () {
				$("#edit-favorite-delivery-address-modal .g-messaging").show();
				$("#edit-delivery-address-submit").prop("disabled", "");
				$("#edit-delivery-address-submit").removeClass("disabled");
			}
		});
	}

	// end Favorite Delivery Addresses
});

if ($("#cp-managed-payments").length) {
	$(
		(function () {
			braintree.client.create(
				{
					authorization: $("#BrainTreeEnvKey").val()
				},
				function (clientErr, clientInstance) {
					if (clientErr) {
						console.error("Error creating client:", clientErr);
						return;
					}

					braintree.dataCollector.create(
						{
							client: clientInstance,
							paypal: true
						},
						function (err, dataCollectorInstance) {
							if (err) {
								// Handle error
								return;
							}

							var deviceData = dataCollectorInstance.deviceData;
							var deviceDataObj = JSON.parse(deviceData);
							$("#PayPalDeviceId").val(deviceDataObj.correlation_id);
						}
					);

					// Create a PayPal Checkout component.
					braintree.paypalCheckout.create(
						{
							client: clientInstance
						},
						function (paypalCheckoutErr, paypalCheckoutInstance) {
							if (paypalCheckoutErr) {
								$("#payment-error-message").html(paypalCheckoutErr);
								$.magnificPopup.open({
									items: {
										src: "#payment-error",
										type: "inline"
									}
								});
								return;
							}

							// Set up PayPal with the checkout.js library
							paypal.Button.render(
								{
									env: $("#BrainTreeEnvVal").val(),

									style: {
										label: "generic",
										size: "responsive",
										shape: "pill",
										color: "blue",
										tagline: false
									},

									funding: {
										disallowed: [paypal.FUNDING.CREDIT]
									},

									payment: function () {
										return paypalCheckoutInstance.createPayment({
											flow: "vault",
											billingAgreementDescription: $(
												"#BrainTreeBillDesc"
											).val(),
											enableShippingAddress: false,
											shippingAddressEditable: false
										});
									},

									onAuthorize: function (data, actions) {
										return paypalCheckoutInstance.tokenizePayment(
											data,
											function (err, payload) {
												$("#PayPalNonceResult").val(payload.nonce);
												$("#PayPalPayerId").val(payload.details.payerId);
												$("#PayPalZipCode").val(
													payload.details.billingAddress.postalCode
												);
												$("#PayPalFirstName").val(payload.details.firstName);
												$("#PayPalLastName").val(payload.details.lastName);

												//check for deviceId, if empty prevent submission
												if ($("#PayPalDeviceId").val()) {
													$("#add-credit-card").submit();
												} else {
													$.magnificPopup.open({
														items: {
															src: "#payment-error",
															type: "inline"
														}
													});
												}
											}
										);
									},

									onCancel: function (data) {
										console.log(
											"checkout.js payment cancelled",
											JSON.stringify(data, 0, 2)
										);
									},

									onError: function (err) {
										$("#payment-error-message").html(error.message);
										$.magnificPopup.open({
											items: {
												src: "#payment-error",
												type: "inline"
											}
										});
									}
								},
								"#paypal-container"
							).then(function () {
								// This function will be called when the PayPal button
								// is set up and ready to be used.
							});
						}
					);
				}
			);
		})(window.jQuery, window, document)
	);
}
