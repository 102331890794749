import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
import { Validation } from "../../util/Validation";

export class StcDonationForm extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		const form = this.form;
		if (!form) {
			throw new Error("Could not locate form element");
		}

		this.applyValidationRules(form);
		form.addEventListener("submit", e => this.handleSubmission(e));
	}

	private get form(): HTMLFormElement | null {
		return this._element.querySelector<HTMLFormElement>("form");
	}

	private handleSubmission(e: Event): void {
		if (!this.validate()) {
			e.preventDefault();
		}
	}

	private validate(): boolean {
		const form = this.form;
		if (form) {
			return $(form).valid();
		}

		return false;
	}

	private applyValidationRules(form: HTMLFormElement): void {
		const messages = this._element.dataset;

		$(form).validate({
			highlight: Validation.cfaHighlight.bind(null),
			messages: {
				Email: {
					email: messages.invalidEmail ?? "Email address is invalid",
					required: messages.requiredEmail ?? "Email field is required",
					validateEmail: messages.invalidEmail ?? "Email address is invalid"
				},
				FirstName: {
					required: messages.requiredFirstName ?? "First Name field is required"
				},
				LastName: {
					required: messages.requiredLastName ?? "Last Name field is required"
				},
				Message: {
					required: messages.requiredMessage ?? "Message field is required"
				}
			},
			rules: {
				Email: {
					email: true,
					required: true,
					validateEmail: true
				},
				FirstName: {
					required: true
				},
				LastName: {
					required: true
				},
				Message: {
					required: true
				}
			},
			unhighlight: Validation.cfaUnhighlight.bind(null)
		});
	}
}

ComponentFactory.registerComponent("StcDonationForm", StcDonationForm);
