import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

class BCVideoModule extends Component {
	public bcScript?: HTMLElement;
	private readonly cssShowModuleClassName: string;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.cssShowModuleClassName = "showModule";
		const accountID = this.getAccountID();
		const playerID = this.getPlayerID();
		const playerHTMLID = this.getPlayerHTMLID();

		if (!playerHTMLID) {
			throw new Error("Missing element id attribute on video HTML element.");
		}

		this.initBcScript({ accountID, playerID });
		this.initPlayer(playerHTMLID);
	}

	initBcScript({
		accountID = "1918791242001",
		playerID = "qY8LyOaAw_default"
	}: {
		accountID: string | undefined;
		playerID: string | undefined;
	}): void {
		const bcUrl =
			`//players.brightcove.net/${accountID}` +
			`/${playerID}_default/index.min.js`;

		const existingBcScript = $(`script[src="${bcUrl}"]`);
		// check if a script already exists
		if (existingBcScript.length > 0) {
			this.bcScript = existingBcScript[0];
		} else {
			this.loadBcScript(bcUrl);
		}
	}

	loadBcScript(bcUrl: string): void {
		// append brightcove script if it doesnt exist
		this.bcScript = document.createElement("script");
		// Hardcoded to account ID.
		this.bcScript.setAttribute("src", `${bcUrl}`);
		// append to document
		document.head.appendChild(this.bcScript);
	}

	initPlayer(playerHTMLID: string): void {
		const script = this.bcScript;
		if (!script) {
			throw new Error(`No Brightcove script found in <head>. Value: ${script}`);
		}

		// Failsafe: Apply in case browser prevents autoplay fn
		script.onload = () => {
			const myPlayer = videojs.getPlayer(playerHTMLID);
			myPlayer?.ready(() => {
				myPlayer.muted(true);
				const promise = myPlayer.play();
				if (promise !== undefined) {
					promise.catch(error => {
						console.warn(`Error in video: ${error}`);
					});
				}
			});
		};
	}

	getAccountID(): string | undefined {
		return this._element.querySelector("video")?.dataset.account || undefined;
	}

	getPlayerID(): string | undefined {
		return this._element.querySelector("video")?.dataset.player || undefined;
	}

	getPlayerHTMLID(): string | undefined {
		return this._element.querySelector("video")?.id || undefined;
	}
}

ComponentFactory.registerComponent("BCVideoModule", BCVideoModule);
