import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class SocialShare extends Component {
	private readonly _offsetTop?: number = 0;

	constructor(_element: HTMLElement, app: ICfa) {
		super(_element, app);
		this._offsetTop = this._element?.offsetTop;
		const shareMoreBtn = _element.querySelector<HTMLElement>(".share-more-btn");

		const _shareCloseBtn = _element.querySelector<HTMLElement>(
			".share-close-btn"
		);

		const socialIcons = _element.querySelector<HTMLElement>(".social-icons");

		this._element.querySelectorAll<HTMLElement>(".share-btn").forEach(el => {
			el.addEventListener("click", e => this.onShare(e));
		});

		window.addEventListener("scroll", this.onScroll);

		shareMoreBtn?.addEventListener("click", e => {
			e.preventDefault();
			socialIcons?.classList.add("active");
		});

		_shareCloseBtn?.addEventListener("click", e => {
			e.preventDefault();
			socialIcons?.classList.remove("active");
		});
	}

	public onScroll = (): void => {
		if (this._element) {
			if (this._offsetTop) {
				Math.floor(document.documentElement.scrollTop) >= this._offsetTop
					? this._element?.classList.add("locked")
					: this._element?.classList.remove("locked");
			}
		}
	};

	private onShare(e: MouseEvent): void {
		const target = e.target as Element;
		if (!target) {
			return;
		}

		const toggle = target.getAttribute("data-action");

		if (toggle !== "email") {
			e.preventDefault();
		}

		(dataLayer || []).push({
			category: "SocialShareEngagement",
			event: "engagement"
		});

		/**
		 * https://developers.facebook.com/docs/workplace/sharing/share-dialog/
		 * Width and height were taken from facebook's example though i have
		 * seen examples of width: 550px and height: 450px used before.
		 */
		const shareWidth = 626;
		const shareHeight = 436;
		const windowHeight = window.innerHeight;
		const windowWidth = window.innerWidth;
		const half = 2;
		const shareValue = target.getAttribute("href") || "";

		const shareWindowProperties = `height=${shareHeight}, width=${shareWidth},
			top=${windowHeight / half} - ${shareHeight / half},
			left=${windowWidth / half} - ${shareWidth / half},
			toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`;

		if (toggle !== "email") {
			window.open(shareValue, "Share", shareWindowProperties);
		}
	}
}

ComponentFactory.registerComponent("socialShare", SocialShare);
